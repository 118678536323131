import React, { useState, useEffect, useRef } from 'react'
import axios from '../Login/axiosConfig'
import { ListItem, ListItemText, Divider, TextField, Button, Paper, Stack, Typography, Box, Link, Fab } from '@mui/material';
import { ClickAwayListener, Tooltip } from '@mui/material';
import { convertDomainToValidURL } from '../Helpers/Helpers';


const Admin = ({ handleScreenChangeClick, setShowMessages, apiKey, setApiKey, createNewAPIKey }) => {
    const [user, setUser] = useState(null);
    const [name, setName] = useState("")
    const [company, setCompany] = useState("")
    const [website, setWebsite] = useState("")
    const [companyDescription, setCompanyDescription] = useState("")
    const [industry, setIndustry] = useState("")
    const [email, setEmail] = useState("")
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        const getCurrentUser = async () => {
            try {
                console.log("in fetch data")
                const response = await axios.get('/api/v1/getUser', {
                    timeout: 10000
                });
                console.log("User:", response.data)
                setUser(response.data)
                setName(response.data.fullName)
                setCompany(response.data.userCompanyName)
                setWebsite(response.data.websiteUrl)
                setEmail(response.data.email)
                setIndustry(response.data.industry)
                setCompanyDescription(response.data.companyDescription)
            } catch (error) {
                console.log(error)
                setShowMessages({
                    alertType: "error",
                    title: "There was an error processing request",
                    message: "Please try back at a later time",
                })
            }
        }

        getCurrentUser()

    }, []);






    const handleSubmit = () => {
        let cleanUrl = ""
        if (website != "") {
            cleanUrl = convertDomainToValidURL(website)
            if (cleanUrl === "badUrl") {
                setShowMessages({
                    alertType: "error",
                    title: "Website URL issue",
                    message: "Please check your site's URL to make sure it's formatted correctly. Example: https://www.datasnack.ai/",
                })
                return
            }
        }


        const register = {
            fullName: name,
            userCompanyName: company,
            websiteUrl: cleanUrl,
            companyDescription,
            industry

        }
        console.log("data from submit", register)

        axios.post('/api/v1/updateUser', register)
            .then(response => {
                setShowMessages({
                    alertType: "success",
                    title: "Success",
                    message: "Update completed.",
                })
                console.log(response.data)
                let toSend = response.data
                toSend.selectedIndex = "Dashboard"
                toSend.updateDash = true
                handleScreenChangeClick(toSend)
            }).catch(error => {

                console.log("error", error)
                setShowMessages({
                    alertType: "error",
                    title: "There was an error processing request",
                    message: "Please try back at a later time",
                })
            });
    }

    return (
        <>
            <div style={{
                backgroundColor: "#F5F7F8",
                minHeight: "1024px",
                // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23262f71' fill-opacity='0.05'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
            }}>

                <div style={{
                    display: "flex", justifyContent: "space-between", flexDirection: "column",
                    paddingLeft: "5%",
                    paddingRight: "5%",
                    paddingTop: "15px",
                    paddingBottom: "15px",

                }}>
                    <Stack width={"100%"} direction="column" marginTop={5} >
                        <Typography marginTop={0} sx={{ fontSize: 26, typography: { fontFamily: "Roboto", fontWeight: 900 }, lineHeight: '90%', color: "#212325" }} fontStyle={"normal"} component="div">
                            Admin
                        </Typography>
                        <Stack marginTop={3}>

                            <TextField
                                autoFocus={false}
                                margin="dense"
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                                id="name"
                                label="Name"
                                type="text"
                                fullWidth
                                value={name}
                                variant="standard"
                            />
                            <TextField
                                autoFocus={false}
                                disabled
                                margin="dense"
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                                id="email"
                                label="Email"
                                type="email"
                                fullWidth
                                value={email}
                                variant="standard"
                            />
                            <TextField
                                autoFocus={false}
                                margin="dense"
                                onChange={(e) => {
                                    setCompany(e.target.value)
                                }}
                                id="company"
                                label="Company"
                                type="text"
                                value={company}
                                fullWidth
                                variant="standard"
                            />
                            <TextField
                                autoFocus={false}
                                margin="dense"
                                onChange={(e) => {
                                    // add validation
                                    setWebsite(e.target.value)
                                }}
                                id="website"
                                label="Website"
                                type="url"
                                fullWidth
                                value={website}
                                variant="standard"
                            />

                            <TextField
                                autoFocus={false}
                                margin="dense"
                                onChange={(e) => {
                                    // add validation
                                    setIndustry(e.target.value)
                                }}
                                id="industry"
                                label="Industry"
                                type="text"
                                fullWidth

                                value={industry}
                                variant="standard"
                            />

                            <TextField
                                autoFocus={false}
                                margin="dense"
                                onChange={(e) => {
                                    // add validation
                                    setCompanyDescription(e.target.value)
                                }}
                                id="companyDescription"
                                label="Company Description"
                                type="text"
                                fullWidth
                                multiline
                                value={companyDescription}
                                variant="standard"
                            />





                            <Stack width={"100%"} direction="row" spacing={2} marginTop={2} justifyContent={"flex-end"} >

                                <Button onClick={() => {
                                    let toSend = {
                                        selectedIndex: "Dashboard",
                                    }
                                    handleScreenChangeClick(toSend)
                                }}>Cancel</Button>
                                <Button onClick={handleSubmit} >Update</Button>
                            </Stack>

                        </Stack>

                    </Stack>
                    <Stack width={"100%"} direction="column" marginTop={5} >
                        <Typography marginTop={0} sx={{ fontSize: 26, typography: { fontFamily: "Roboto", fontWeight: 900 }, lineHeight: '90%', color: "#212325" }} fontStyle={"normal"} component="div">
                            API Keys
                        </Typography>

                        <Stack direction={"row"} justifyContent={"flex-start"}
                            alignItems={"center"} spacing={5} marginTop={3}>

                            <Button 
                            variant="outlined"
                            onClick={createNewAPIKey}>Get New API Key</Button>

                            {apiKey &&

                                <ClickAwayListener onClickAway={handleTooltipClose}>
                                    <div>
                                        <Tooltip
                                            PopperProps={{
                                                disablePortal: true,
                                            }}
                                            onClose={handleTooltipClose}
                                            open={open}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            title="Copied To Clipboard"
                                        >
                                            <Typography
                                                onClick={() => {
                                                    handleTooltipOpen()
                                                    navigator.clipboard.writeText(apiKey)
                                                }}
                                                marginTop={0} sx={{ cursor: "pointer", fontSize: 16, typography: { fontFamily: "Roboto", fontWeight: 400 }, lineHeight: '90%', color: "#212325" }} fontStyle={"normal"} component="div">
                                                {apiKey}
                                            </Typography>

                                        </Tooltip>
                                    </div>
                                </ClickAwayListener>











                            }
                        </Stack>

                    </Stack>
                </div>

            </div>
        </>
    )

}

export default Admin