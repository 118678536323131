import React, { useEffect, useState, useRef } from 'react'

import { Typography, Stack, InputAdornment, IconButton, TextField, Button, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import SearchIcon from '@mui/icons-material/Search';
import CreateIcon from '@mui/icons-material/Create';
import SendIcon from '@mui/icons-material/Send'
import useWindowDimensions from './useWindowDimensions';
import SocialShare from '../Components/SocialShare';
import Typewriter from '../Components/Typewriter';



const PlayGame = ({ soundEffectObj, gameData, gameDetails, handleGameInput, handleCreateChaos, handleScreenChangeClick, setShowMessages }) => {
    const scrollRef = useRef(null);
    const inputRef = useRef(null)
    let workInterval = useRef(null)



    const [updateLine, setUpdateLine] = useState(0)
    const [userInput, setUserInput] = useState("")
    const [shouldClearText, setShouldClearText] = useState(false)
    const [showScore, setShowScore] = useState(false)
    const [shouldCallBot, setShouldCallBot] = useState(false)
    const { height, width } = useWindowDimensions();
    // const [workInterval, setWorkInterval] = useState({})
    const [musicIndex, setMusicIndex] = useState(0)
    const [turnNumber, setTurnNumber] = useState(0)

    const animateWorking = (setUserInput) => {
        console.log("in animate working...")
        let globalIndex = 0
        const eWorking = `g...`
        let index = 0
        let textAccumulator = "workin"
    
        workInterval.current = setInterval(() => {
            console.log("current work interval",  workInterval.current)
            textAccumulator = textAccumulator + eWorking[index];
            setUserInput(textAccumulator);
            index++
            globalIndex++
            if (index === eWorking.length) {
                index = 0
                textAccumulator = "workin"
            }
            // failsafe in case something weird happens
            if (globalIndex > 50) {
                clearInterval(workInterval.current)
            }
    
        }, 750)
    
    
    
    
    }


    // handle audio sound effects based on type of gamedata received
    useEffect(() => {
        if (gameData.length === 0) {
            return
        }
       

        let newMsg = gameData[gameData.length - 1]
        console.log("handle audio new msg:", newMsg)

        if (newMsg.isBot) {

            soundEffectObj.chaosAudioRef.current.play().then(() => {
                console.log("chaos playing")
            }).catch((err) => {
                console.log(err)
            })

            return
        }


       

    }, [gameData])

    // handle scroll to next line effect
    useEffect(() => {
        if (updateLine === 0) {
                 clearInterval(workInterval.current)
        setUserInput("")
            console.log("swap waiting for typewriter")
            fadeOut(soundEffectObj.waitingAudioRef, soundEffectObj.typewriterAudioRef)
            return
        }


        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behaviour: "smooth" });
        }

        if (updateLine === -1) {
            console.log("music stopped")

            // play finished audio
            fadeOut(soundEffectObj.typewriterAudioRef)

            if (gameDetails.isGameFinished) {
                console.log("got score - do something with it", gameDetails.score)
                setTimeout(() => {
                    setShowScore(true)
                    scrollRef.current.scrollIntoView({ behaviour: "smooth" });
                }, 1 * 1000)
    
            }


        }
    }, [updateLine])

    // general details about the game
    useEffect(() => {
        console.log("game details useEffect called")
        inputRef.current.focus();
        if (scrollRef.current) {
            console.log("scroll into view")
            scrollRef.current.scrollIntoView({ behaviour: "smooth" });
        }
        // clears working... animator

        console.log("workInterval.current - clear from gameDetails useEffect", workInterval.current)
        clearInterval(workInterval.current)
        setUserInput("")

        // if (gameDetails.isGameFinished) {
        //     console.log("got score - do something with it", gameDetails.score)
        //     setTimeout(() => {
        //         setShowScore(true)
        //     }, 5 * 1000)

        // }

        return () => {
            clearInterval(workInterval.current)
            localStorage.setItem(`gameId-${gameDetails.gameId}`, JSON.stringify(gameData[gameData.length - 1]))
        }
    }, [gameDetails]);



   

    const fadeOut = (myAudio, nxtAudio) => {


        let fadeAudio = setInterval(() => {
            // check to make sure myAudio is valid while in loop
            if (!myAudio) {
                console.log("myAudio is null", myAudio)
                clearInterval(fadeAudio);
                if (nxtAudio) {
                    console.log("playing", nxtAudio)
                    nxtAudio.current.play().then(() => {
                        nxtAudio.current.volume = 1.0
                    }).catch((err) => {
                        console.log(err)
                    })
                }
                return
            }
            let fadeVol = myAudio.current.volume
            fadeVol -= 0.1
            if (fadeVol <= 0.0) {
                clearInterval(fadeAudio);
                myAudio.current.load()
                console.log("nxtAudio", nxtAudio)
                if (nxtAudio) {
                    console.log("playing", nxtAudio)
                    nxtAudio.current.play().then(() => {
                        nxtAudio.current.volume = 1.0
                    }).catch((err) => {
                        console.log(err)
                    })
                }

            } else {
                myAudio.current.volume = fadeVol
            }

        }, 125)

    }


    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            // Do something with the message
            console.log("enter was pressed");
            handleGameSubmit()
        }
    };

    const handleGameSubmit = () => {

        let localTurnNumber = turnNumber + 1
        soundEffectObj.sentMessageAudioRef.current.play().then(() => {
            console.log("sent msg played")
        }).catch((err) => {
            console.log(err)
        })



        console.log("submit...")
        setShouldClearText(true)
        if (userInput === "") {
            return
        }
        const clientInput = userInput


        handleGameInput({
            // messageId: crypto.randomUUID(),
            message: clientInput,
            messageType: "user",
            gameId: gameDetails.gameId,
            threadId: gameDetails.threadId,
            turnNumber: localTurnNumber


        })
        setTurnNumber(turnNumber + 1)

        animateWorking(setUserInput)


        // soundEffectObj.waitingAudioRef.current.play().then(() => {
        //     soundEffectObj.waitingAudioRef.current.volume = 0.1
        // }).catch((err) => {
        //     console.log(err)
        // })

    }


    return (
        <div style={{
            // backgroundColor: "#f4f5f6",
            backgroundColor: "#212325",
            height: height * .935
            // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23262f71' fill-opacity='0.05'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
        }}>



            <Stack
                // height={height * .93}
                height={"100%"}
                direction={"column"} alignContent={"flex-start"} justifyContent={"space-between"}>

                <Stack direction={"row"}
                    // paddingTop={window.$isMobile ? "15%" : "5%"}
                    paddingLeft={"2.5%"}
                    paddingRight={"2.5%"}
                    alignItems={"center"}
                    paddingTop={2}
                    paddingBottom={2}
                    justifyContent={"space-between"}>

                    <Button

                        variant="text" onClick={() => {
                            if (gameDetails.returnUrl === "https://www.datasnack.ai/" || !gameDetails.returnUrl) {
                                handleScreenChangeClick({
                                    selectedIndex: "GameHome"
                                })

                            } else {
                                window.location.href = gameDetails.returnUrl

                            }

                        }} sx={{ textTransform: "none" }}  >
                        {gameDetails && <Stack spacing={1} direction="row" alignItems="center">
                            <ArrowBackIcon sx={{ color: "#ffffff", fontSize: 12, }} />
                            {!gameDetails.returnUrl || gameDetails.returnUrl === "https://www.datasnack.ai/" ? <Typography sx={{ typography: { fontFamily: "Roboto", fontWeight: 400 }, fontSize: 12, color: "#ffffff" }} fontStyle={"normal"}>
                                Leave SnackSurvey
                            </Typography> :
                                <Typography sx={{ typography: { fontFamily: "Roboto", fontWeight: 400 }, fontSize: 12, color: "#ffffff" }} fontStyle={"normal"}>
                                    {`Return to (${gameDetails.returnUrl ?? ""})`}
                                </Typography>}
                        </Stack>}
                    </Button>
                    {gameDetails && <SocialShare allData={gameDetails} />}

                </Stack>

                {/* game text */}
                <Box overflow="scroll" height={height * .70} sx={{ backgroundColor: "#212325", }}>
                    <Stack
                        paddingLeft={"5%"}
                        paddingRight={"5%"}
                        paddingTop={"2.5%"}
                        paddingBottom={"2.5%"}
                        alignItems={"flex-start"}
                        spacing={1}
                        direction={"column"} >

                        {gameData && gameData.map((e) => (
                            <Stack key={e.messageId} marginBottom={5}>
                                {/* buddy bot response */}
                                {e.isBot &&

                                    <Typewriter origText={e.message} textColor={"#f9d154"} setUpdateLine={setUpdateLine} />
                                }

                                {/* user response */}
                                {!e.isBot && e.messageType === "user" && <Typography


                                    marginBottom={2}
                                    sx={{
                                        whiteSpace: "pre-wrap",
                                        wordBreak: "break-word",
                                        textAlign: "left", typography: { fontFamily: "CourierPrime-Regular" },
                                        fontSize: 16, lineHeight: '105%', color: "#ffffff"
                                    }} fontStyle={"normal"}>
                                    <span style={{ fontFamily: "CourierPrime-Bold" }}>Me: </span>{e.message}

                                </Typography>}

                                {/* assistant - instructions - questions */}
                                {!e.isBot && e.messageType !== "user" &&

                                    <Typewriter origText={e.message} textColor={"#86c696"} setUpdateLine={setUpdateLine} />

                                }





                            </Stack>
                        ))}
                        {/* <Typography ref={scrollRef} /> */}





                        {showScore &&

                            <Stack direction={"column"} width={"100%"} alignItems={"center"}>
                                <Typography
                                    sx={{
                                        whiteSpace: "pre-wrap",
                                        wordBreak: "break-word",
                                        textAlign: "center", typography: { fontFamily: "CourierPrime-Regular" },
                                        fontSize: 45, lineHeight: '105%', color: "#86c696"
                                    }} fontStyle={"normal"}>
                                    Great Job!

                                </Typography>
                                < Typography
                                    sx={{
                                        whiteSpace: "pre-wrap",
                                        wordBreak: "break-word",
                                        textAlign: "center", typography: { fontFamily: "CourierPrime-Bold" },
                                        fontSize: 54, lineHeight: '105%', color: "#86c696"
                                    }} fontStyle={"normal"}>
                                    Score: {gameDetails.score}

                                </Typography>

                                < Typography
                                    sx={{
                                        whiteSpace: "pre-wrap",
                                        wordBreak: "break-word",
                                        textAlign: "center", typography: { fontFamily: "CourierPrime-Bold" },
                                        fontSize: 54, lineHeight: '105%', color: "#86c696"
                                    }} fontStyle={"normal"}>
                                    Thanks For Playing!

                                </Typography>
                            </Stack>}
                            <Typography ref={scrollRef} />


                    </Stack>

                </Box>


                <Stack marginTop={2} marginBottom={5} direction="column" justifySelf={"flex-end"} alignItems={"center"}>

                    <TextField
                        ref={inputRef}
                        size={"small"}
                        focused={true}
                        variant={"outlined"}
                        margin={"none"}
                        onFocus={() => {

                            if (shouldClearText) {
                                setUserInput("")
                                setShouldClearText(false)

                            }
                        }}
                        sx={{
                            width: window.$isMobile ? "325px" : "500px",

                            '& .MuiInputBase-root': {
                                color: "#ffffff7f",
                                borderRadius: 1,
                                typography: { fontFamily: "Roboto", fontWeight: 400 }, fontSize: 16,
                                '& .MuiOutlinedInput-root': {
                                    color: "#ffffff",

                                }
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: "#ffffff7f",
                                borderWidth: "1px"
                            },
                            "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                    borderColor: "#ffffff7f",
                                    borderWidth: "1px"
                                }
                            }


                        }}

                        id="searchInput"
                        type={"text"}
                        onChange={(e) => {

                            setUserInput(e.target.value)
                        }}
                        onKeyDown={handleKeyDown}

                        value={userInput}
                        InputProps={{
                            startAdornment:
                                <InputAdornment position={"start"}>
                                    {userInput === "" ?
                                        <CreateIcon sx={{
                                            fontSize: 15,
                                            //  marginRight: 2,
                                            color: "#ffffff7f", opacity: 0.8
                                        }} />
                                        : null}
                                </InputAdornment>,

                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="submit"



                                        onClick={handleGameSubmit}
                                        edge="end"
                                    >
                                        {userInput === "" ? null : <SendIcon sx={{ fontSize: 15, color: "#ffffff", opacity: 0.8 }} />}
                                    </IconButton>
                                </InputAdornment>

                        }}

                        label={""}

                    />

                </Stack>


            </Stack>
        </div>
    )

}

export default PlayGame







