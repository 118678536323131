import React, { useState, useEffect, useRef } from 'react'
import axios from '../../Login/axiosConfig.js'

import {
    TextField, Divider, Backdrop, CircularProgress,
    Button, FormGroup, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Stack, Typography
} from '@mui/material';

import { convertDomainToValidURL } from '../../Helpers/Helpers';


const RiskDomainIntel = ({ fcn, data, user, getDomainIntel, addRiskSiloData, globalRiskSiloId, setGlobalRiskSiloId, clientCompanies, globalClientCompanyId, setGlobalClientCompanyId }) => {


    const [topic, setTopic] = useState("")




    const handleSubmit = () => {
        if (topic === "") {
            return
        }

        const register = {
            riskSiloId: globalRiskSiloId,
            user,
            topic,
            clientCompanyId: globalClientCompanyId


        }
        console.log("data from submit", register)
        fcn(register)

    }

 

    return (
        <Stack direction="column" sx={{
            backgroundColor: "#F5F7F8", borderRadius: 5,
            padding: 2.5,
            paddingTop: 5,

        }} >


            <Typography marginTop={0} sx={{ fontSize: 26, typography: { fontFamily: "Roboto", fontWeight: 900 }, lineHeight: '90%', color: "#006ec2" }} fontStyle={"normal"} component="div">
                Domain Intelligence
            </Typography>
            <Stack marginTop={3}
                marginBottom={10}
                spacing={3}>

                {clientCompanies && <FormControl
                 
                  
                    fullWidth >
                    <InputLabel id="demo-simple-select-label">Client Companies</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={globalClientCompanyId}
                        label="Target Recipient"
                        onChange={(event) => {

                            setGlobalClientCompanyId(event.target.value)

                        }}
                    >
                        {clientCompanies.map((e) => (
                            <MenuItem key={e.clientCompanyId} value={e.clientCompanyId}>{e.companyName}</MenuItem>
                        ))}


                    </Select>
                </FormControl>}

                {addRiskSiloData && <FormControl
                    fullWidth >
                    <InputLabel id="demo-simple-select-label">Risk Silo</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={globalRiskSiloId}
                        label="Client Companies"
                        onChange={(event) => {
                            setTopic("")
                            setGlobalRiskSiloId(event.target.value)

                        }}
                    >
                        {addRiskSiloData.map((e) => (
                            <MenuItem key={e.riskSiloId} value={e.riskSiloId}>{e.riskSiloName}</MenuItem>
                        ))}


                    </Select>
                </FormControl>}

                <TextField
                    autoFocus={false}
                    margin="dense"
                    onChange={(e) => {
                        setTopic(e.target.value)
                    }}
                    id="description"
                    label="Domain Documents"
                    type="text"
                    fullWidth
                    multiline
                    value={topic}
                    variant="standard"
                />



                <Stack width={"100%"} direction="row" spacing={2} marginTop={2} justifyContent={"flex-end"} >

                    <Button onClick={() => {
                        setTopic("")

                    }}>Clear</Button>
                    <Button onClick={handleSubmit} >Submit</Button>
                </Stack>

            </Stack>

            {data && data.map((e) => (
                <Stack key={e.referenceSourceId}>
                    <Typography
                        key={e.referenceSourceId}


                        sx={{
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                            textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 900 },
                            fontSize: window.$isMobile ? 42 : 64, lineHeight: '105%', color: "#212325"
                        }} fontStyle={"normal"}>
                        {e.snippet}
                    </Typography>
                    <Divider sx={{ marginBottom: 4, backgroundColor: "#006ec2", width: "100%", borderBottomWidth: 10 }} variant="middle" />
                </Stack>
            ))}


        </Stack>
    )

}

export default RiskDomainIntel