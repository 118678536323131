import React, { useEffect, useState, useRef } from 'react'
import { Typography, Stack, Button, Box, Chip, Backdrop, CircularProgress, Link, Divider } from '@mui/material';


import useWindowDimensions from './useWindowDimensions';
import Footer from '../Components/Footer.js'

import InsightsIcon from '@mui/icons-material/Insights';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Diversity3Icon from '@mui/icons-material/Diversity3';

import homepageHolder from '../assets/images/heroTop.png'

import aiImage from '../assets/images/ai-image.jpg'

import HeroCard from '../Components/HeroCard.js';
import DividerCard from '../Components/DividerCard.js';

import box_1 from '../assets/images/boxImages/box_1.webp'
import box_2 from '../assets/images/boxImages/box_2.webp'
import box_3 from '../assets/images/boxImages/box_3.webp'
import box_4 from '../assets/images/boxImages/sq_1.webp'
import box_5 from '../assets/images/boxImages/sq_2.webp'
import box_6 from '../assets/images/boxImages/sq_3.webp'

import dsScreenShot from '../assets/images/boxImages/ds_image1.png'

const heroPageGameId = "9fe859b9-7a4d-4e35-bd99-01f00762573a"

const ForBrands = ({ getAllDataSnackGames, startNewGame, continueGame, allGames, openLogin, setShowMessages, handleScreenChangeClick }) => {
    const scrollRef = useRef(null);
    const [updateLine, setUpdateLine] = useState(0)
    const [showProgress, setShowProgress] = useState(false)
    const { height, width } = useWindowDimensions();
    const [useCase, setUseCase] = useState(useCases[0])
    const [showTypewriter, setShowTypewriter] = useState(useCases[0])
    const [heroScrollShown, setHeroScrollShown] = useState(false)


    useEffect(() => {
        // window.scrollTo(0, 0)
        console.log("forbrands useffect")
        let scrollStatus = localStorage.getItem('heroScrollShown');
        if (scrollStatus) {
            setHeroScrollShown(true)
        }
        return () => {
            setShowProgress(false)
        }

    }, []);

    useEffect(() => {

        if (updateLine === -1) {
            localStorage.setItem('heroScrollShown', "true")
            setHeroScrollShown(true)
        }


    }, [updateLine])

    const tryDemo = () => {
      
            handleScreenChangeClick({
                selectedIndex: "RiskCheckerDashboard"
      
    })
}


    return (
        <div style={{
            // backgroundColor: "#f4f5f6",
            backgroundColor: "#ffffff",
            // minHeight: "1024px",
            width: "100%"
            // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23262f71' fill-opacity='0.05'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
        }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showProgress}

            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* hero page */}
            <Box sx={{ backgroundColor: "#499dd7" }}>
                <Stack
                    paddingLeft={"5%"}
                    paddingRight={window.$isMobile ? "5%" : "5%"}
                    paddingTop={window.$isMobile ? "50px" : "1%"}
                    paddingBottom={window.$isMobile ? 15 : "5%"}
                    alignItems={"center"}
                    spacing={window.$isMobile ? 0 : 10}

                    direction={window.$isMobile ? "column" : "row"} justifyContent={"space-between"} >
                    <Stack spacing={2} direction={"column"} width={window.$isMobile ? "100%" : "100%"}>
                        <Typography

                            sx={{
                                textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 900 },
                                fontSize: window.$isMobile ? 64 : 64, lineHeight: '105%', color: "#ffffff"
                            }} fontStyle={"normal"}>
                            <span style={{ color: "#000000" }}>AI Risk Analysis </span>{window.$isMobile ? null : <br />}For Reliable Insights
                        </Typography>
                        <Typography
                            paddingBottom={window.$isMobile ? 4 : 0} paddingRight={window.$isMobile ? "10%" : "15%"}
                            sx={{
                                textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: window.$isMobile ? 400 : 400 },
                                fontSize: window.$isMobile ? 16 : 16, lineHeight: '105%', color: "#ffffff"
                            }} fontStyle={"normal"}>

                            DataSnack Prevents AI Hallucinations, Safeguarding Your Organizational Integrity

                        </Typography>


                        {!window.$isMobile && <Button onClick={() => {
                            handleScreenChangeClick({
                                selectedIndex: "Contact"
                            })
                            // window.location.href = "https://calendly.com/brianjmarvin-_80u/30min"
                        }} sx={{ backgroundColor: "#ffffff", width: "200px", height: "45px" }}>
                            <Typography sx={{
                                textTransform: "none",
                                typography: { fontFamily: "Roboto", fontWeight: 700 },
                                fontSize: 14,
                                color: "#006ec2"
                            }} >
                                Book a Demo Today!
                            </Typography>
                        </Button>}

                    </Stack>

                    <Stack width={window.$isMobile ? "90%" : "100%"}>
                        <img src={homepageHolder} alt="Location Image" style={{ width: "100%" }} />

                    </Stack>


                </Stack>


            </Box>

            {/* Try Demo Divider */}
            <Stack width={"100%"} direction={"row"} justifyContent={"center"} zIndex={(theme) => theme.zIndex.drawer + 1} marginTop={-7.5}>
                <DividerCard image={aiImage} tryDemo={tryDemo}
                    title={"Insurance Claims Agent Demo"}
                    content={"Observe how an insurance claims team leverages DataSnack to ensure the accuracy of coverage communications with customers. DataSnack cross-references proposed correspondences with existing policies, identifying any factual inaccuracies before they reach the client."} />
            </Stack>

            {/* The Problem */}
            <Box sx={{ backgroundColor: "#ffffff" }}>
                <Stack direction={"column"} alignItems={"center"} spacing={2}
                    paddingTop={window.$isMobile ? 15 : 20}
                    paddingBottom={window.$isMobile ? 5 : 10}
                    paddingLeft={"5%"}
                    paddingRight={"5%"}
                >
                    <Typography


                        paddingLeft={window.$isMobile ? "0%" : "20%"}
                        paddingRight={window.$isMobile ? "0%" : "20%"}
                        sx={{

                            typography: { fontFamily: "Roboto", fontWeight: 900 },
                            textAlign: "center", fontSize: window.$isMobile ? 42 : 54,
                            lineHeight: '105%', color: "#00000"
                        }} >
                        <span style={{ color: "#499dd7" }}>Navigating the Risks</span>{window.$isMobile ? null : <br />} Of GenAI Hallucinations
                    </Typography>
                    <Typography
                        paddingLeft={window.$isMobile ? 0 : "15%"}
                        paddingRight={window.$isMobile ? 0 : "15%"}

                        sx={{

                            typography: { fontFamily: "Roboto", fontWeight: 400 },
                            textAlign: "center", fontSize: window.$isMobile ? 16 : 16,
                            lineHeight: '105%', color: "#37383a"
                        }} >
                        Addressing Misinformation, Policy Non-Compliance, and Knowledge Inconsistencies with Confidence
                    </Typography>

                    <Stack direction={window.$isMobile ? "column" : "row"} spacing={4} justifyContent={"space-between"} paddingTop={5} paddingBottom={10}>
                        {problems && problems.map((e) => (
                            <Stack key={e.title} width={window.$isMobile ? "100%" : "35%"}>
                                <HeroCard image={e.image} title={e.title}
                                    content={e.content}
                                    cta={"Something"}
                                    height={"285px"}
                                />

                            </Stack>



                        ))}

                    </Stack>



                </Stack>

            </Box>


            {/* The DataSnack Solution */}
            <Box sx={{
                backgroundColor: "#ffffff",
                // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23262f71' fill-opacity='0.05'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,

            }}>

                <Stack
                    paddingTop={window.$isMobile ? "0%" : "0%"}
                    paddingBottom={window.$isMobile ? 10 : 10}
                    paddingLeft={"5%"}
                    paddingRight={window.$isMobile ? "5%" : "0%"}

                    spacing={1}

                    direction={"column"} justifyContent={"space-between"} alignItems={"center"}>

                    <Typography


                        paddingLeft={window.$isMobile ? "0%" : "20%"}
                        paddingRight={window.$isMobile ? "0%" : "20%"}
                        sx={{

                            typography: { fontFamily: "Roboto", fontWeight: 900 },
                            textAlign: "center", fontSize: window.$isMobile ? 42 : 54,
                            lineHeight: '105%', color: "#00000"
                        }} >
                        <span style={{ color: "#499dd7" }}>How DataSnack Resolves </span>{window.$isMobile ? null : <br />}GenAI Challenges
                    </Typography>
                    <Typography
                        paddingLeft={window.$isMobile ? 0 : "15%"}
                        paddingRight={window.$isMobile ? 0 : "15%"}

                        sx={{

                            typography: { fontFamily: "Roboto", fontWeight: 400 },
                            textAlign: "center", fontSize: window.$isMobile ? 16 : 16,
                            lineHeight: '105%', color: "#37383a"
                        }} >
                        Ensuring Information Accuracy, Policy Adherence, and Consistent Knowledge Application
                    </Typography>



                    <Stack paddingTop={5} width={"100%"}
                        direction={window.$isMobile ? "column" : "row"} spacing={1}
                        justifyContent={"center"} alignItems={"center"}>

                        <Stack direction={"row"} flexWrap={'wrap'} width={window.$isMobile ? "100%" : "65%"} >
                            {solutions && solutions.map((e) => (
                                <Stack key={e.title} padding={2} direction={"column"} width={window.$isMobile ? "100%" : "40%"}
                                    spacing={2} alignItems={"flex-start"}>
                                    <Typography marginTop={1} sx={{

                                        textAlign: "left",
                                        typography: { fontFamily: "Roboto", fontWeight: 600 },
                                        fontSize: 24,
                                        lineHeight: "110%",

                                        color: "#499dd7"
                                    }} >
                                        {e.title}
                                    </Typography>

                                    <Typography marginTop={1} sx={{
                                        textAlign: "left",
                                        typography: { fontFamily: "Roboto", fontWeight: 400 },
                                        fontSize: 14,
                                        lineHeight: "110%",

                                        color: "#37383a"
                                    }} >
                                        {e.content}
                                    </Typography>

                        

                                </Stack>
                            ))}

                        </Stack>


                        <Stack 
                            sx={{
                                overflow: "hidden",
                                // borderRadius: 1,
                                boxShadow: 2,
                            }}>
                            {/* Fix sizing on divider for mobile */}
                            <img src={dsScreenShot} alt="Location Image" style={{ width: window.$isMobile ? "100%" : "750px",
                              
                             }} />
                        </Stack>

                        {/* <Stack >
                            <img src={dsScreenShot} alt="Rocket" style={{ width: window.$isMobile ? "400px" : "400px", }} />
                        </Stack> */}
                    </Stack>



                </Stack>

            </Box>

            {/* Use Cases */}
            <Box sx={{
                backgroundColor: "#f7f9fa",
                // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23262f71' fill-opacity='0.05'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,

            }}>

                <Stack
                    paddingTop={window.$isMobile ? 10 : "5%"}
                    paddingBottom={window.$isMobile ? 10 : 5}
                    paddingLeft={"5%"}
                    paddingRight={"5%"}

                    spacing={1}

                    direction={"column"} justifyContent={"space-between"} alignItems={"center"}>

                    <Typography


                        paddingLeft={window.$isMobile ? "0%" : "20%"}
                        paddingRight={window.$isMobile ? "0%" : "20%"}
                        sx={{

                            typography: { fontFamily: "Roboto", fontWeight: 900 },
                            textAlign: "center", fontSize: window.$isMobile ? 42 : 54,
                            lineHeight: '105%', color: "#00000"
                        }} >
                        <span style={{ color: "#499dd7" }}>Practical Use Cases </span>{window.$isMobile ? null : <br />}For DataSnack in Your Organization
                    </Typography>
                    <Typography
                        paddingLeft={window.$isMobile ? 0 : "15%"}
                        paddingRight={window.$isMobile ? 0 : "15%"}

                        sx={{

                            typography: { fontFamily: "Roboto", fontWeight: 400 },
                            textAlign: "center", fontSize: window.$isMobile ? 16 : 16,
                            lineHeight: '105%', color: "#37383a"
                        }} >
                        Enhancing Accuracy, Compliance, and Efficiency Across Diverse Operational Scenarios
                    </Typography>



                    <Stack paddingTop={window.$isMobile ? 5 : 5} width={window.$isMobile ? "100%" : "85%"} direction={window.$isMobile ? "column" : "row"} spacing={10} justifyContent={"center"} alignItems={"center"}>

                        <Stack direction={"row"} flexWrap={'wrap'}   >
                            {useCases && useCases.map((e) => (
                                <Stack key={e.title} padding={2} direction={"column"} width={window.$isMobile ? "100%" : "350px"} spacing={2} alignItems={"flex-start"}>
                                    <Typography marginTop={1} sx={{

                                        textAlign: "left",
                                        typography: { fontFamily: "Roboto", fontWeight: 600 },
                                        fontSize: 24,
                                        lineHeight: "110%",

                                        color: "#212325"
                                    }} >
                                        {e.title}
                                    </Typography>

                                    <Typography marginTop={1} sx={{
                                        textAlign: "left",
                                        typography: { fontFamily: "Roboto", fontWeight: 400 },
                                        fontSize: 14,
                                        lineHeight: "110%",

                                        color: "#37383a"
                                    }} >
                                        {e.scenario}
                                    </Typography>
                                    <Typography marginTop={1} sx={{
                                        textAlign: "left",
                                        typography: { fontFamily: "Roboto", fontWeight: 400 },
                                        fontSize: 14,
                                        lineHeight: "110%",

                                        color: "#212325"
                                    }} >
                                        {e.usage}
                                    </Typography>

                                    <Typography marginTop={1} sx={{
                                        textAlign: "left",
                                        typography: { fontFamily: "Roboto", fontWeight: 500 },
                                        fontSize: 14,
                                        lineHeight: "110%",

                                        color: "#499dd7"
                                    }} >
                                        {e.solution}
                                    </Typography>

                                </Stack>
                            ))}

                        </Stack>

                        {/* <img src={useCaseHolder} alt="Rocket" style={{ width: window.$isMobile ? "350px" : "400px", }} /> */}

                    </Stack>



                </Stack>

            </Box>


            {/* competitor advantage */}
            <Box sx={{ backgroundColor: "#ffffff" }}>
                <Stack direction={"column"} alignItems={"center"} spacing={2}
                    paddingTop={window.$isMobile ? 10 : "5%"}
                    paddingBottom={window.$isMobile ? 2.5 : 2.5}
                    paddingLeft={"5%"}
                    paddingRight={"5%"}
                >

                    <Typography


                        paddingLeft={window.$isMobile ? "0%" : "20%"}
                        paddingRight={window.$isMobile ? "0%" : "20%"}
                        sx={{

                            typography: { fontFamily: "Roboto", fontWeight: 900 },
                            textAlign: "center", fontSize: window.$isMobile ? 42 : 54,
                            lineHeight: '105%', color: "#00000"
                        }} >
                        <span style={{ color: "#499dd7" }}>Why Choose DataSnack </span>{window.$isMobile ? null : <br />}Over Traditional Compliance Solutions
                    </Typography>
                    <Typography
                        paddingLeft={window.$isMobile ? 0 : "15%"}
                        paddingRight={window.$isMobile ? 0 : "15%"}

                        sx={{

                            typography: { fontFamily: "Roboto", fontWeight: 400 },
                            textAlign: "center", fontSize: window.$isMobile ? 16 : 16,
                            lineHeight: '105%', color: "#37383a"
                        }} >
                        Experience Real-Time Accuracy, Comprehensive Integration, and Seamless Usability
                    </Typography>




                    <Stack direction={window.$isMobile ? "column" : "row"} spacing={4} justifyContent={"space-between"} paddingTop={5} paddingBottom={10}>
                        {advantages && advantages.map((e) => (
                            <Stack key={e.title} width={window.$isMobile ? "100%" : "30%"}>
                                <HeroCard image={e.image} title={e.title}
                                    cta={"Something"}
                                    content={e.content} height={"300px"} />

                            </Stack>



                        ))}

                    </Stack>



                </Stack>

            </Box>



            {/* CTA*/}
            <Box sx={{ backgroundColor: "#ffffff" }}>
                <Stack direction={"column"} alignItems={"center"} spacing={2}
                    // paddingTop={window.$isMobile ? 10 : "5%"}
                    paddingBottom={window.$isMobile ? 10 : "5%"}
                    paddingLeft={"5%"}
                    paddingRight={"5%"}
                >

                    <Typography paddingBottom={4}
                        paddingLeft={window.$isMobile ? "0%" : "20%"}
                        paddingRight={window.$isMobile ? "0%" : "20%"}
                        paddingTop={window.$isMobile ? "0%" : "2.5%"}
                        sx={{

                            typography: { fontFamily: "Roboto", fontWeight: window.$isMobile ? 900 : 900 },
                            textAlign: "center", fontSize: window.$isMobile ? 34 : 54,
                            lineHeight: '105%', color: "#000000"
                        }} >
                        Empower your organization with <span style={{ color: "#499dd7", fontWeight: 900 }}>DataSnack</span>, the ultimate solution for accurate, compliant, and contextually rich AI-driven insights.
                    </Typography>
                    <Button
                        onClick={() => {
                            handleScreenChangeClick({
                                selectedIndex: "Contact"
                            })
                            // window.location.href = "https://calendly.com/brianjmarvin-_80u/30min"
                        }}
                        sx={{
                            backgroundColor: "#006ec2",
                            width: "250px",
                            height: "45px"
                        }}>
                        <Typography sx={{
                            textTransform: "none",
                            typography: { fontFamily: "Roboto", fontWeight: 700 },
                            fontSize: 18,
                            color: "#ffffff"
                        }} >
                            Book a Demo Today!
                        </Typography>
                    </Button>

                </Stack>

            </Box>




            <Footer handleScreenChangeClick={handleScreenChangeClick} />



        </div>
    )

}

export default ForBrands


const problems = [
    {
        image: box_1,
        title: "Misleading Information Dissemination",
        content: "Generative AI can sometimes produce seemingly plausible but factually incorrect information, leading to the spread of misinformation. This can confuse clients and stakeholders, eroding trust and potentially causing financial and reputational damage to the organization."
    },
    {
        image: box_2,
        title: "Non-Compliance with Policies",
        content: "AI-generated responses might inadvertently contradict internal organizational policies or regulatory requirements. This non-compliance can expose the organization to legal risks and undermine strategic initiatives tailored to adhere to strict guidelines."
    },
    {
        image: box_3,
        title: "Inconsistent Knowledge Application",
        content: "An AI model may fail to correctly contextualize specialized industry knowledge or historical data relevant to a specific query. Such inconsistencies can result in incorrect advice or decisions that don’t align with the organization’s established practices, impacting operational efficiency and reliability."
    }


]

const advantages = [
    {
        image: box_4,
        title: "Real-Time Risk Analysis",
        content: "Unlike traditional compliance tools that may require extensive manual review, DataSnack's Risk Engine provides real-time analysis of AI-generated content. This instantaneous feedback allows organizations to address potential issues swiftly, reducing the chance of errors slipping through and ensuring timely, accurate decision-making."
    },
    {
        image: box_5,
        title: "Comprehensive Knowledge Integration",
        content: "DataSnack goes beyond simple rule-based compliance checks by integrating internal expert insights, organizational policies, and detailed product information into a unified knowledge graph. This holistic approach ensures that compliance checks are not only accurate but also contextually relevant, providing a deeper layer of understanding and reliability."
    },
    {
        image: box_6,
        title: "User-Friendly Interface and API",
        content: "DataSnack offers a user-friendly interface and a robust API, making it easier for organizations to integrate compliance monitoring into their existing workflows. Whether through manual checks or automated API calls, DataSnack's intuitive design enables seamless adoption, enhancing operational efficiency without adding complexity."
    }


]

const pricing = [
    {
        icon: <InsightsIcon sx={{ fontSize: 62, color: "#ffffff" }} />,
        title: "Starter",
        price: "Free",
        content: ["20 Self-Distributed SnackSurveys per month",
            "100 Self-Distributed responses per month",
            "AI-Generated survey analysis (10 games per session)",
            "24/7 email support",
            "Data exports (CSV)"]
    },
    {
        icon: <FileCopyIcon sx={{ fontSize: 62, color: "#ffffff" }} />,
        title: "Premier",
        price: "Contact Us For Pricing",
        content: ["100 Self-Distributed SnackSurveys per month",
            "500 Self-Distributed responses per month",
            "AI-Generated survey analysis",
            "500 Targeted user insights per month",
            "24/7 email support",
            "Data exports (CSV, TXT, JSONL)",
            "Website embed (Coming Soon)"]
    },
    {
        icon: <Diversity3Icon sx={{ fontSize: 62, color: "#ffffff" }} />,
        title: "Enterprise",
        price: "Contact Us For Pricing",
        content: ["500 Self-Distributed SnackSurveys per month",
            "2,500 Self-Distributed responses per month",
            "AI-Generated survey analysis",
            "2,500 Targeted user insights per month",
            "24/7 email support",
            "Data exports (CSV, TXT, JSONL)",
            "Website embed (Coming Soon)"]
    }

]





const solutions = [
    {
        title: "Accurate Knowledge Graphs",
        content: "DataSnack consolidates internal expert insights, organizational policies, and detailed product information into a cohesive knowledge graph. This ensures that all AI-generated insights and correspondences are cross-referenced against verified data, significantly reducing the risk of misinformation.",
        solution: "Solution"
    },
    {
        title: "Real-Time Risk Analysis",
        content: "With DataSnack's Risk Engine, users can instantly identify and assess potential risks in AI-generated responses. By highlighting discrepancies and policy violations in real-time, organizations can act swiftly to rectify issues before they impact decision-making and compliance.",
        solution: "Solution"
    },
    {
        title: "Customizable Policy Integration",
        content: "Organizations can upload specific policies and guidelines directly into DataSnack, alongside default policies provided by the platform. This capability ensures that all AI-generated content strictly adheres to both internal and regulatory standards, minimizing the risk of non-compliance.",
        solution: "Solution"
    },
    {
        title: "Expert-Driven Contextual Insights",
        content: "DataSnack facilitates dynamic survey creation that gathers input from department experts, capturing crucial contextual knowledge and industry-specific nuances. These insights enrich the knowledge graph, enabling more contextually accurate and reliable AI responses, thereby addressing knowledge inconsistencies effectively.",
        solution: "Solution"
    },

]

const useCases = [
    {
        title: "Insurance Claim Adjustments",
        scenario: "Client disputes due to inaccurate or policy-violating claim information.",
        usage: "DataSnack ensures that AI-generated claim assessments are accurate and compliant with internal policies by cross-referencing against a comprehensive knowledge graph. This reduces client disputes and builds trust through reliable, policy-adherent information.",
        outcome: ""
    },
    {
        title: "Customer Service for Complex Machinery",
        scenario: "Miscommunication about product specifications and maintenance, leading to prolonged downtime.",
        usage: "By integrating detailed product specifications and common troubleshooting data into its knowledge graph, DataSnack helps customer service representatives provide precise, contextually accurate advice. This reduces downtime and enhances customer satisfaction.",
        outcome: ""
    },
    {
        title: "Financial Product Sales",
        scenario: "Inconsistent and potentially misleading information on complex financial instruments.  ",
        usage: "DataSnack cross-references all sales correspondence with updated financial data and internal guidelines, ensuring that sales reps deliver accurate and compliant information. This builds client trust and reduces the risk of financial misinformation.",
        outcome: ""
    },
    {
        title: "HR Policy Compliance",
        scenario: "Job listings and internal documents that violate internal policies or regulatory requirements.  ",
        usage: "DataSnack scans job listings and internal documents against both HR policies and government regulations. This ensures compliance, mitigates legal risks, and maintains a consistent organizational standard.",
        outcome: ""
    },
    {
        title: "Government Zoning Advice",
        scenario: "Incorrect zoning information leading to legal complications and misinformation. ",
        usage: "DataSnack aggregates complex zoning regulations into a clear, actionable knowledge graph. This enables government employees to provide accurate, compliant zoning advice, reducing legal risks and improving client trust.",
        outcome: ""
    },
    {
        title: "Chatbot Response Accuracy",
        scenario: "Chatbots providing high-risk or inaccurate information in sensitive industries like finance and insurance.  ",
        usage: "DataSnack analyzes and verifies chatbot responses against a proprietary knowledge graph populated with verified expert insights and organizational data. This ensures that chatbots deliver accurate, reliable, and compliant information, mitigating risks.",
        outcome: ""
    },


]


