import * as React from 'react';
import {
  Button, Card, CardHeader, CardMedia,
  CardContent, CardActions, IconButton, Typography
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';

import SocialShare from './SocialShare';


export default function GameCard({ getAllDataSnackGames, width, setShowProgress, gameData, startNewGame, continueGame }) {


  const handleProgressClose = () => {
    setTimeout(() => {
      setShowProgress(false)
    }, 20 * 1000)
  }

  return (
    <Card sx={{ width: width }}>
      <CardHeader
        // avatar={
        //   <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
        //     G
        //   </Avatar>
        // }

        // title={"P&G"}
        subheader={<SocialShare allData={gameData} buttonColor={"#37383a"} />}
      />
      <CardMedia
        component="img"
        height="300px"
        onError={()=>{getAllDataSnackGames("DATASNACK_GAME")}}
        src={gameData.imageUrl}
        sx={{objectPosition: "50% 0%"}}
        // image={gameData.imageUrl}
        alt="Basic The Game"
      />
      <CardContent sx={{height: "75px", overflow: "hidden"}}>
        
        <Typography marginTop={1} sx={{
          textTransform: "none",
    
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          textAlign: "left",
          WebkitLineClamp: 2,
          typography: { fontFamily: "Roboto", fontWeight: 500 },
          fontSize: 12,
          lineHeight: "110%",
        
          color: "#57585a"
        }} >
          {gameData.clientChallenge ?? ""}
        </Typography>
      </CardContent>
      <CardActions >
        <Button startIcon={<FavoriteIcon sx={{ fontSize: 20, color: "#006ec2", opacity: 0.8 }} />} onClick={() => {
          // setShowProgress(true)
          startNewGame(gameData.gameId, "")
          // handleProgressClose()
        }} aria-label="add to favorites">
                    
          <Typography sx={{
            textTransform: "none",
            typography: { fontFamily: "Roboto", fontWeight: 700 },
            fontSize: 14,
            color: "#006ec2"
          }} >
            Play Game
          </Typography>
        </Button>
        <Button startIcon={<FavoriteIcon sx={{ fontSize: 20, color: "#006ec2", opacity: 0.8 }} />} onClick={() => {
         
          continueGame(gameData.gameId)
          handleProgressClose()
        }} aria-label="share">
         
         <Typography sx={{
            textTransform: "none",
            typography: { fontFamily: "Roboto", fontWeight: 700 },
            fontSize: 14,
            color: "#006ec2"
          }} >
           Continue Game
          </Typography>
        </Button>

      </CardActions>

    </Card>
  );
}