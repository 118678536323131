import React, { useEffect } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Button, Stack, Typography } from '@mui/material';



const CampaignAnalysis = ({ content, handleDownload, handleScreenChangeClick }) => {

    useEffect(() => {
        window.scrollTo(0, 0)
        console.log("content:", content)

    }, []);

    return (

        <div style={{
            backgroundColor: "#ffffff",
            minHeight: "1024px",
            // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23262f71' fill-opacity='0.05'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
        }}>
            <Stack direction={"row"}

                paddingLeft={"2.5%"}
                paddingRight={"2.5%"}
                alignItems={"center"}
                paddingTop={2}
                justifyContent={"flex-start"}>

                <Button

                    variant="text" onClick={() => {

                        handleScreenChangeClick({
                            selectedIndex: "AllCampaigns"
                        })


                    }} sx={{ textTransform: "none" }}  >
                    <Stack spacing={1} direction="row" alignItems="center">
                        <ArrowBackIcon sx={{ color: "#37383a", fontSize: 12, }} />
                        <Typography sx={{ typography: { fontFamily: "Roboto", fontWeight: 400 }, fontSize: 12, color: "#37383a" }} fontStyle={"normal"}>
                            Back
                        </Typography>
                    </Stack>
                </Button>


            </Stack>

            <div style={{
                display: "flex", flexDirection: "column",
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingTop: "15px",


            }}>

                <Stack direction={"column"} marginBottom={4} alignItems={"flex-start"} justifyContent={"flex-start"} spacing={1} >
                    <Typography sx={{ fontSize: 26, typography: { fontFamily: "Roboto", fontWeight: 900 }, lineHeight: '90%', color: "#37383a" }} fontStyle={"normal"}>
                        SnackSurvey Analysis
                    </Typography>
                    <Button variant='text' onClick={() => {
                        // transform data
                        let downloadData = content[0].allGames.map((e) => {
                            return {
                                sessionId: e.threadId,
                                gameId: e.gameId,
                                analysis: e.analysis,
                                gameStartedAt: e.createdAt,
                                gameEndedAt: e.updatedAt,
                                pointsReceived: e.pointsReceived,
                                totalResponses: e.responseTotal
                            }
                        })
                        handleDownload(downloadData)
                    }}>
                        <Typography
                            sx={{
                                textTransform: "none",
                                typography: { fontFamily: "Roboto", fontWeight: 500 },
                                fontSize: 12, lineHeight: '105%', color: "#006ec2"
                            }} fontStyle={"normal"}>
                            Download Games Analysis
                        </Typography>
                    </Button>

                </Stack>

                {content && content.map((v) => (
                    <Stack key={v.gameResearchId} direction={"column"} marginBottom={5} spacing={1} alignItems={"flex-start"} width={"100%"}>
                        {/* Campaign Synopsis */}

                        <Typography

                            sx={{
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-word",
                                textTransform: "none",
                                textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 700 },
                                fontSize: 14, lineHeight: '105%', color: "#212325"
                            }} fontStyle={"normal"}>
                            SUMMARY
                        </Typography>
                        <Typography
                            sx={{
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-word",
                                textTransform: "none",
                                textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 400 },
                                fontSize: 14, lineHeight: '105%', color: "#212325"
                            }} fontStyle={"normal"}>
                            {v.overarchingSummary ?? ""}
                        </Typography>

                        <Typography
                            paddingTop={3}
                            sx={{
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-word",
                                textTransform: "none",
                                textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 700 },
                                fontSize: 14, lineHeight: '105%', color: "#212325"
                            }} fontStyle={"normal"}>
                            RECENT GAME SUMMARIES
                        </Typography>
                        {v.allGames && v.allGames.map((e, i) => (

                            <React.Fragment key={e.threadId}>
                                <Typography
                                    paddingBottom={3}
                                    sx={{
                                        whiteSpace: "pre-wrap",
                                        wordBreak: "break-word",
                                        textTransform: "none",
                                        textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 400 },
                                        fontSize: 12, lineHeight: '105%', color: "#212325"
                                    }} fontStyle={"normal"}>
                                    Game {i + 1}:
                                </Typography>
                                {/* <Typography

                                    paddingBottom={3}
                                    sx={{
                                        whiteSpace: "pre-wrap",
                                        wordBreak: "break-word",
                                        textTransform: "none",
                                        textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 400 },
                                        fontSize: 12, lineHeight: '105%', color: "#212325"
                                    }} fontStyle={"normal"}>
                                    {e.analysis ?? ""}
                                </Typography> */}
                                <Typography

                                    paddingBottom={3}
                                    sx={{
                                        whiteSpace: "pre-wrap",
                                        wordBreak: "break-word",
                                        textTransform: "none",
                                        textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 400 },
                                        fontSize: 12, lineHeight: '105%', color: "#212325"
                                    }} fontStyle={"normal"}>
                                    {e.productSuggestions ?? ""}
                                </Typography>
                            </React.Fragment>

                        ))}



                    </Stack>
                ))}

            </div>

        </div>

    )

}

export default CampaignAnalysis