import React, { useState, useEffect } from 'react'
import {
    FormControl, InputLabel, Select, MenuItem, TextField,
    Button, Stack, Typography
} from '@mui/material';
import FileUploader from './FileUpload';



const Campaign = ({ setShowCreateCampaign, createCampaign, user }) => {
    // console.log("user", user)

    const [fileInfo, setFileInfo] = useState({});
    const [tone, setTone] = useState(toneList[0])
    const [theme, setTheme] = useState(themeList[0])
    const [turns, setTurns] = useState(10)
    const [campaignName, setCampaignName] = useState("")
    const [clientChallenge, setClientChallenge] = useState("")
    const [backgroundInfo, setBackgroundInfo] = useState("")
    const [industry, setIndustry] = useState("")
    const [returnUrl, setReturnUrl] = useState("")
    const [engagement, setEngagement] = useState(engagementType[0])
    const [targetAudience, setTargetAudience] = useState(targetAudienceArray[0])
    const [productsScrapeUrl, setProductsScrapeUrl] = useState("")
    const [showProgress, setShowProgress] = useState(false)


    useEffect(() => {
        window.scrollTo(0, 0)

        return () => {
            setShowProgress(false)
        }

    }, []);


    return (


        <Stack direction="column" marginTop={5} sx={{ backgroundColor: "#e8e9e97f", borderRadius: 2, padding: 5 }} >
            <Typography marginTop={0} sx={{ fontSize: 20, typography: { fontFamily: "Roboto", fontWeight: 600 }, lineHeight: '90%', color: "#37383a" }} fontStyle={"normal"}>
                Create a New SnackSurvey
            </Typography>
            <Stack marginTop={2}>

                <TextField
                    autoFocus={false}
                    margin="dense"
                    onChange={(e) => {
                        setCampaignName(e.target.value)
                    }}
                    id="name"
                    label="SnackSurvey Name"
                    type="text"
                    fullWidth
                    required
                    value={campaignName}
                    variant="standard"
                />

                {engagementType && <FormControl fullWidth sx={{ marginTop: 4 }}>
                    <InputLabel id="demo-simple-select-label">Engagement Solution</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={engagement}
                        label="Engagement Solution"
                        onChange={(event) => {
                            setEngagement(event.target.value)

                        }}
                    >
                        {engagementType.map((e) => (
                            <MenuItem key={e} value={e}>{e}</MenuItem>
                        ))}


                    </Select>
                </FormControl>}

                <TextField
                    autoFocus={false}
                    margin="dense"
                    onChange={(e) => {
                        setClientChallenge(e.target.value)
                    }}
                    required
                    id="challenge"
                    label="Business Challenge and/or Insights Requested"
                    type="text"
                    multiline
                    fullWidth
                    value={clientChallenge}
                    variant="standard"
                />

<TextField
                    autoFocus={false}
                    margin="dense"
                    onChange={(e) => {
                        setIndustry(e.target.value)
                    }}
                    required
                    id="industry"
                    label="Industry"
                    type="text"
                    multiline
                    fullWidth
                    value={industry}
                    variant="standard"
                />


                <TextField
                    // sx={{ marginTop: 4 }}
                    autoFocus={false}
                    margin="dense"
                    onChange={(e) => {
                        setBackgroundInfo(e.target.value)
                    }}
                    id="company"
                    multiline
                    label="Supporting Reference Details"
                    type="text"
                   
                    value={backgroundInfo}
                    fullWidth
                    variant="standard"
                />

                {/* <TextField
                    // sx={{ marginTop: 4 }}
                    autoFocus={false}
                    margin="dense"
                    onChange={(e) => {
                        setProductsScrapeUrl(e.target.value)
                    }}
                    id="productPage"
                    multiline
                    label="Product Page URL"
                    type="url"
                    value={productsScrapeUrl}
                    fullWidth
                    variant="standard"
                /> */}

                {targetAudience && <FormControl fullWidth sx={{ marginTop: 4 }}>
                    <InputLabel id="demo-simple-select-label">Target Audience</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={targetAudience}
                        label="Target Audience"
                        onChange={(event) => {
                            setTargetAudience(event.target.value)

                        }}
                    >
                        {targetAudienceArray.map((e) => (
                            <MenuItem key={e} value={e}>
                                <Typography paddingBottom={1}

                                    sx={{
                                        whiteSpace: "pre-wrap",
                                        wordBreak: "break-word",
                                        typography: { fontFamily: "Roboto", fontWeight: 400 },
                                        textAlign: "left", fontSize: window.$isMobile ? 16 : 16,
                                        lineHeight: '105%', color: "#37383a"
                                    }} >
                                    {e}
                                </Typography>


                            </MenuItem>
                        ))}


                    </Select>
                </FormControl>}

                {toneList && <FormControl fullWidth sx={{ marginTop: 3 }}>
                    <InputLabel id="demo-simple-select-label">Tone</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={tone}
                        label="GameTone"
                        onChange={(event) => {
                            setTone(event.target.value)

                        }}
                    >
                        {toneList.map((e) => (
                            <MenuItem key={e} value={e}>{e}</MenuItem>
                        ))}


                    </Select>
                </FormControl>}

                {/* {themeList && <FormControl fullWidth sx={{ marginTop: 4 }}>
                    <InputLabel id="demo-simple-select-label">Theme</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={theme}
                        label="GameTone"
                        onChange={(event) => {
                            setTheme(event.target.value)

                        }}
                    >
                        {themeList.map((e) => (
                            <MenuItem key={e} value={e}>
                                <Typography paddingBottom={1}

                                    sx={{
                                        whiteSpace: "pre-wrap",
                                        wordBreak: "break-word",
                                        typography: { fontFamily: "Roboto", fontWeight: 400 },
                                        textAlign: "left", fontSize: window.$isMobile ? 16 : 16,
                                        lineHeight: '105%', color: "#37383a"
                                    }} >
                                    {e}
                                </Typography>


                            </MenuItem>
                        ))}


                    </Select>
                </FormControl>} */}

                {/* <TextField
                    autoFocus={false}
                    margin="dense"
                    onChange={(e) => {
                        setReturnUrl(e.target.value)
                    }}
                    id="returnUrl"
                    label="Redirect Url - ex. https://www.datasnack.ai/"
                    type="url"
                    value={returnUrl}
                    fullWidth
                    variant="standard"
                /> */}

                <TextField
                    sx={{ marginTop: 3 }}
                    autoFocus={false}
                    margin="dense"
                    onChange={(e) => {

                        setTurns(parseInt(e.target.value))
                    }}
                    id="turns"
                    label="Max Questions"
                    type="number"
                    fullWidth
                    value={turns}
                    variant="standard"
                />


                <Stack marginTop={5} marginBottom={2}>
                    <FileUploader
                        setFileInfo={setFileInfo}
                    />
                </Stack>






                <Stack
                    // width={"100%"}
                    direction="row" spacing={2} marginTop={2} justifyContent={"flex-end"} >

                    <Button onClick={() => {
                        setShowCreateCampaign(false)
                    }}>Cancel</Button>
                    <Button onClick={() => {
                        setShowProgress(true)
                        if (theme === "No theme") {
                            setTheme('')
                        }
                        createCampaign({
                            campaignName,
                            engagement,
                            clientChallenge,
                            backgroundInfo,
                            tone,
                            theme,
                            fileInfo,
                            turns,
                            returnUrl,
                            targetAudience,
                            productsScrapeUrl,
                            industry
                        })

                    }} >Create Survey</Button>
                </Stack>

            </Stack>

        </Stack>


    )

}

export default Campaign

const toneList = [
    "Neutral",
    "Sarcastic",
    "Funny",
    "Serious",
    "Whimsical"
]

const themeList = [
    `No theme`,
    `Every Sunday, Willow and Yvette as their laundry is spinning fall into various adventures. `,
    `Larry is the holder of the magical AI bot that can see into the future... but there is a cost to having the power to see into the future... He has to help businesses solve their problems quickly or great disaster will happen`,
    `Danny has the uncanny ability to recognize patterns within data before disaster hits his territory. From the information given, Danny has to make a decision of the optimal strategy to prevent danger.`,
    `Jerry ventures to launch a new startup that can save the world. They race against time to perfect the product before it's too late.`,
]

const targetAudienceArray = [
    `Recent customers`,
    `Past customers`,
    `Social media followers`,
    `Website visitors`,
    `Employees`,
    `Event attendees`,
    `Customer service recipients`
]

const engagementType = [
    `Ask Me Anything`,
    `Survey`,
    `Adventure Game`,
   
]