import React, { useState, useEffect } from 'react'
import { Avatar, Fab, Button, Stack, Typography, Backdrop, CircularProgress, Divider } from '@mui/material';
import Campaign from './Campaign';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import SocialShare from '../Components/SocialShare';


const AllCampaigns = ({ user, handleDelete, getGameResearch, getCampaigns, allCampaigns, startNewGame, createCampaign, analyzeCampaign }) => {

    const [showCreateCampaign, setShowCreateCampaign] = useState(false)
    const [showProgress, setShowProgress] = useState(true)

    useEffect(() => {
        console.log("all campaigns use effect")
        window.scrollTo(0, 0)

        // if (allCampaigns === null) {
            getCampaigns()
        // }

        console.log(allCampaigns)

    }, []);

    useEffect(() => {
        let createTimer = {}
        if (allCampaigns !== null) {
            setShowCreateCampaign(false)
            setShowProgress(false)
        } else {
            // stop progress after 3 seconds if no data and trigger create campaign
            createTimer = setTimeout(() => {
                if (allCampaigns === null) {
                    setShowProgress(false)
                    setShowCreateCampaign(true)
                }
            }, 3000)
        }




        return () => {
            clearTimeout(createTimer)
        }
    }, [allCampaigns])



    return (
        <>
            <div style={{
                backgroundColor: "#ffffff",
                minHeight: "1024px",
                // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23262f71' fill-opacity='0.05'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
            }}>

                <div style={{
                    display: "flex", flexDirection: "column",
                    paddingLeft: "5%",
                    paddingRight: "5%",
                    paddingTop: "15px",


                }}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={showProgress}

                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Stack direction={"row"} paddingBottom={2} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography sx={{ fontSize: 26, typography: { fontFamily: "Roboto", fontWeight: 900 }, lineHeight: '90%', color: "#37383a" }} fontStyle={"normal"}>
                            SnackSurveys
                        </Typography>
                        <Fab sx={{ backgroundColor: '#006ec2aa', paddingLeft: 5, paddingRight: 5 }} onClick={() => {
                            setTimeout(() => {
                                setShowCreateCampaign(!showCreateCampaign)
                            }, 200)

                        }} variant="extended">
                            {showCreateCampaign ? (<CloseIcon sx={{ fontSize: 20, marginRight: 1, color: "#fcfdfd", opacity: 0.8 }} />)
                                : (<AddIcon sx={{ fontSize: 20, marginRight: 1, color: "#fcfdfd", opacity: 0.8 }} />)}



                            <Typography
                                sx={{
                                    textTransform: "none",
                                    textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 900 },
                                    fontSize: 14, lineHeight: '105%', color: "#ffffff"
                                }} fontStyle={"normal"}>
                                {showCreateCampaign ? "Close" : "Create"}
                            </Typography>

                        </Fab>
                    </Stack>
                    {!allCampaigns && <Stack direction={"column"} paddingBottom={1} spacing={4} alignItems={"flex-start"} width={"100%"}>

                        <Typography
                            sx={{
                                textTransform: "none",
                                textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 400 },
                                fontSize: 16, lineHeight: '105%', color: "#37383a"
                            }} fontStyle={"normal"}>
                            No campaigns found. Create a new SnackSurvey below to build your first survey.
                        </Typography>
                    </Stack>}
                    <Stack direction={"column"} paddingBottom={5} width={window.$isMobile ? 350 : "100%"}  >
                        {showCreateCampaign &&
                            <Campaign setShowCreateCampaign={setShowCreateCampaign} user={user} createCampaign={createCampaign} />
                        }
                    </Stack>

                    {/* Campaign List */}
                    <Stack direction={"column"} paddingBottom={5} spacing={4} alignItems={"flex-start"} width={"100%"}>


                        {allCampaigns && allCampaigns.map((e) => (
                            <Stack key={e.gameId} width={window.$isMobile ? "100%" : "50%"} marginBottom={window.$isMobile ? 5 : 0}>
                                <Stack direction={window.$isMobile ? "column" : "row"} alignItems={window.$isMobile ? "center" : "flex-start"} justifyContent={"flex-start"} spacing={3}>
                                    <Avatar
                                        alt="D"
                                        variant='rounded'
                                        src={e.imageUrl}
                                        sx={{ objectPosition: "50% 0%", width: window.$isMobile ? "100%" : 56, height: window.$isMobile ? "100%" : 56 }}
                                    />
                                    <Stack direction={"column"} width={"100%"} alignItems={"flex-start"} >
                                        <Stack direction={"row"} width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
                                            <Stack direction={"row"} width={"50%"} alignItems={"center"} justifyContent={"flex-start"} spacing={1}>
                                                <Typography
                                                    sx={{
                                                        textTransform: "none",
                                                        textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 900 },
                                                        fontSize: 14, lineHeight: '105%', color: "#37383a"
                                                    }} fontStyle={"normal"}>
                                                    {e.campaignName ?? ""}
                                                </Typography>
                                                <DeleteIcon onClick={() => {
                                                    handleDelete(e.gameId)
                                                }} sx={{ cursor: "pointer", fontSize: 14, marginRight: 0, color: "red", opacity: 0.4 }} />

                                            </Stack>


                                            <SocialShare allData={e} buttonColor={'#212325aa'} />
                                        </Stack>
                                        <Typography

                                            sx={{
                                                textTransform: "none",
                                                textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 400 },
                                                fontSize: 14, lineHeight: '105%', color: "#37383a"
                                            }} fontStyle={"normal"}>
                                            {e.clientChallenge ?? ""}
                                        </Typography>
                                        <Typography

                                            sx={{
                                                textTransform: "none",
                                                textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 400 },
                                                fontSize: 14, lineHeight: '105%', color: "#37383a"
                                            }} fontStyle={"normal"}>
                                            {e.targetAudience ?? ""}
                                        </Typography>

                                        {e.campaignStats && e.campaignStats.map((stat) => (
                                            <Typography
                                                marginTop={2}
                                                key={stat.timePlayed}
                                                sx={{
                                                    textTransform: "none",
                                                    textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 400 },
                                                    fontSize: 14, lineHeight: '105%', color: "#37383a"
                                                }} fontStyle={"normal"}>
                                                Sessions <span style={{ fontWeight: 600 }}>{stat.sessions ?? 0}</span> | Total Responses <span style={{ fontWeight: 600 }}>{stat.totalResponses ?? 0}</span> | Time Played <span style={{ fontWeight: 600 }}>{stat.timePlayed}</span> | Total Points <span style={{ fontWeight: 600 }}>{stat.totalPoints ?? 0}</span>
                                            </Typography>
                                        ))}

                                        <Stack width={"100%"} direction={window.$isMobile ? "column" : "row"} marginTop={3} spacing={2} justifyContent={window.$isMobile ? "space-between" : "flex-start"}>
                                            <Button variant="outlined" fullWidth={window.$isMobile ? true : false} onClick={() => {
                                                // setShowProgress(true)
                                                startNewGame(e.gameId, "")
                                            }}>
                                                <Typography
                                                    sx={{
                                                        textTransform: "none",
                                                        typography: { fontFamily: "Roboto", fontWeight: 400 },
                                                        fontSize: 14, lineHeight: '105%', color: "#006ec2"
                                                    }} fontStyle={"normal"}>
                                                    Start SnackSurvey
                                                </Typography>
                                            </Button>
                                            <Button variant='outlined' onClick={() => {
                                                getGameResearch(e)
                                            }}>
                                                <Typography
                                                    sx={{
                                                        textTransform: "none",
                                                        typography: { fontFamily: "Roboto", fontWeight: 400 },
                                                        fontSize: 14, lineHeight: '105%', color: "#006ec2"
                                                    }} fontStyle={"normal"}>
                                                    Review Analysis
                                                </Typography>
                                            </Button>
                                            <Button variant='outlined' onClick={() => {
                                                analyzeCampaign(e)
                                            }}>
                                                <Typography
                                                    sx={{
                                                        textTransform: "none",
                                                        typography: { fontFamily: "Roboto", fontWeight: 400 },
                                                        fontSize: 14, lineHeight: '105%', color: "#006ec2"
                                                    }} fontStyle={"normal"}>
                                                    Analyze New Data
                                                </Typography>
                                            </Button>


                                        </Stack>

                                    </Stack>

                                </Stack>
                                {!window.$isMobile && <Divider sx={{ backgroundColor: "#d6d6d6", marginTop: 3, marginBottom: 1, width: "100%" }} variant="middle" />}

                            </Stack>



                        ))

                        }
                    </Stack>

                </div>

            </div>
        </>
    )

}

export default AllCampaigns