import React, { useState, useEffect, useRef } from 'react'
import axios from '../../Login/axiosConfig.js'

import {
    TextField, Divider,
    Button, FormGroup, FormControl, OutlinedInput, InputAdornment, IconButton, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Stack, Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { convertDomainToValidURL } from '../../Helpers/Helpers.js';


const RiskSilo = ({ fcn, data, user, addRiskSiloData, globalRiskSiloId, setGlobalRiskSiloId, setShowMessages, clientCompanies, globalClientCompanyId, setGlobalClientCompanyId }) => {


   
    
    
    const [newRiskSilos, setNewRiskSilos] = useState([])
    const [newRiskSilo, setNewRiskSilo] = useState("")
    const [riskSiloUpdater, setRiskSiloUpdater] = useState(0)

   

    const handleAddNewRiskSilo = (e) => {
        if (e === "") {
            return
        }
        let temp = newRiskSilos
        temp.push(e)
        setNewRiskSilos(temp)
        console.log("all policies:", temp)
        setNewRiskSilo("")

    };



    const handleSubmit = () => {

        const register = {
            newRiskSilos,
            clientCompanyId: globalClientCompanyId

        }
        console.log("data from submit", register)
        fcn(register)

    }

    return (
        <Stack direction="column" sx={{ backgroundColor: "#F5F7F8", borderRadius: 5,
            padding: 2.5,
            paddingTop: 5,
         
         }} >

            <Typography marginTop={0} sx={{ fontSize: 26, typography: { fontFamily: "Roboto", fontWeight: 900 }, lineHeight: '90%', color: "#006ec2" }} fontStyle={"normal"} component="div">
                Risk Silos
            </Typography>
            <Stack marginTop={3} marginBottom={10} spacing={3}>


               

                {clientCompanies && <FormControl
                  
                    fullWidth >
                    <InputLabel id="demo-simple-select-label">Client Companies</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={globalClientCompanyId}
                        label="Client Companies"
                        onChange={(event) => {
                           
                            setGlobalClientCompanyId(event.target.value)

                        }}
                    >
                        {clientCompanies.map((e) => (
                            <MenuItem key={e.clientCompanyId} value={e.clientCompanyId}>{e.companyName}</MenuItem>
                        ))}


                    </Select>
                </FormControl>}
                    <FormControl

                        fullWidth
                        sx={{ m: 1 }} 
                        variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Add new risk silo</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"

                            onChange={(e) => {
                                setNewRiskSilo(e.target.value)
                            }}
                            fullWidth
                            value={newRiskSilo}
                            type={'text'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => {
                                            handleAddNewRiskSilo(newRiskSilo)
                                        }}
                                        edge="end"
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Add new risk silo"
                        />
                    </FormControl>

                    {newRiskSilos.length != 0 && newRiskSilos.map((val, index) => (

                        <FormControl
                            key={val}
                            fullWidth
                            sx={{ m: 1 }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Risk silo</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"

                                disabled
                                fullWidth
                                value={newRiskSilos[index]}
                                type={'text'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="remove"
                                            onClick={() => {
                                                let temp = newRiskSilos
                                                temp.splice(index, 1);
                                                console.log("new temp array")
                                                setNewRiskSilos(temp)
                                                setRiskSiloUpdater(riskSiloUpdater + 1)
                                            }}
                                            edge="end"
                                        >
                                            <RemoveIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Risk silo"
                            />
                        </FormControl>

                    ))}
               





                <Stack width={"100%"} direction="row" spacing={2} marginTop={2} justifyContent={"flex-end"} >

                    <Button onClick={() => {
                        setNewRiskSilos([])
                    }}>Cancel</Button>
                    <Button onClick={handleSubmit} >Submit</Button>
                </Stack>

            </Stack>

            {addRiskSiloData && addRiskSiloData.map((e) => (
                <Stack   key={e.riskSiloId}>
                    <Typography
                      


                        sx={{
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                            textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 900 },
                            fontSize: window.$isMobile ? 42 : 64, lineHeight: '105%', color: "#212325"
                        }} fontStyle={"normal"}>
                        {e.riskSiloName}
                    </Typography>
                    <Divider sx={{ marginBottom:4,  backgroundColor: "#006ec2", width: "100%", borderBottomWidth: 10 }} variant="middle" />
                </Stack>
            ))}


        </Stack>
    )

}

export default RiskSilo