import React, { useEffect, useState } from 'react'
import { Typography, Stack, Button, Box, Backdrop, CircularProgress } from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Footer from '../Components/Footer.js'
import GameCard from '../Components/GameCard.js';

const GameHome = ({ getAllDataSnackGames, allGames, startNewGame, continueGame, handleScreenChangeClick }) => {
    const [showProgress, setShowProgress] = useState(false)


    useEffect(() => {
        window.scrollTo(0, 0)

        return () => {
            setShowProgress(false)
        }
    }, []);

    

    return (
        <div style={{
            // backgroundColor: "#f4f5f6",
            backgroundColor: "#006ec2",
            minHeight: "1024px",
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23262f71' fill-opacity='0.05'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
        }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showProgress}

            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* <Stack direction={"row"}
              
              paddingLeft={"2.5%"}
              paddingRight={"2.5%"}
              alignItems={"center"}
              paddingTop={2}
               justifyContent={"flex-start"}>

              <Button

                  variant="text" onClick={() => {

                      handleScreenChangeClick({
                          selectedIndex: "GameHome"
                      })


                  }} sx={{ textTransform: "none" }}  >
                  <Stack spacing={1} direction="row" alignItems="center">
                      <ArrowBackIcon sx={{ color: "#ffffff", fontSize: 12, }} />
                      <Typography sx={{ typography: { fontFamily: "Roboto", fontWeight: 400 }, fontSize: 12, color: "#ffffff" }} fontStyle={"normal"}>
                          Back
                      </Typography>
                  </Stack>
              </Button>


          </Stack> */}

          <Typography
                        paddingLeft={window.$isMobile ? "0%" : "20%"}
                        paddingRight={window.$isMobile ? "0%" : "20%"}
                        paddingTop={3}
                        paddingBottom={3}
                        sx={{

                            typography: { fontFamily: "Roboto", fontWeight: 900 },
                            textAlign: "center", fontSize: window.$isMobile ? 34 : 54,
                            lineHeight: '105%', color: "#ffffff"
                        }} >
                     SnackSurveys
                    </Typography>


            {/* hero page */}
            <Box sx={{ minHeight: "1024px" }}>
                <Stack padding={window.$isMobile ? "5%" : 10} spacing={10} marginBottom={4} width={"100%"} justifyContent={"center"} direction="row" useFlexGap flexWrap="wrap">
                    {allGames && allGames.map((e) => (
                        <React.Fragment key={e.gameId} > 
                        <GameCard getAllDataSnackGames={getAllDataSnackGames} width={window.$isMobile ? "100%" :"345px"} setShowProgress={setShowProgress} gameData={e} startNewGame={startNewGame} continueGame={continueGame} />
                        </React.Fragment>
                    ))}
                </Stack>
            </Box>

            {/* <Footer backgroundColor={'#212325'} /> */}



        </div>
    )

}

export default GameHome


