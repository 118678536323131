import * as React from 'react';
import { CssBaseline, AppBar, Drawer, Toolbar, List, ListItemButton, ListItemText, Dialog, DialogContent, Button, Divider, DialogActions, DialogTitle, Typography, Box } from '@mui/material';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ShowMessages from '../Components/ShowMessages';
import Header from '../Components/Header';
import { useState, useEffect, useRef } from 'react'
import axios from '../Login/axiosConfig'
import axiosS3 from 'axios';
import ListSubheader from '@mui/material/ListSubheader';
import Admin from './Admin';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import "../scss/fonts/Courier_Prime/courier.css"
import "../Screens/Home.css"
import { useNavigate, useSearchParams } from "react-router-dom";
import Feedback from './Feedback';
import Contact from './Contact';
import FeedbackIcon from '@mui/icons-material/Feedback';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Privacy from './Privacy';
import TOS from './TOS';
import GameHome from './GameHome';
import ForBrands from './ForBrands';
import PlayGame from './PlayGame';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import DataObjectIcon from '@mui/icons-material/DataObject';
import chaos from '../assets/images/chaos.mp3'
import waitingSound from '../assets/images/waiting.mp3'
import sentMessage from '../assets/images/sentmessage.mp3'
import typewriterSound from '../assets/images/typewriter.mp3';
import DescriptionIcon from '@mui/icons-material/Description';


import GamesIcon from '@mui/icons-material/Games';
import BusinessIcon from '@mui/icons-material/Business';

import AllCampaigns from './AllCampaigns';
import CampaignAnalysis from './CampaignAnalysis';
import GameLoading from './GameLoading';
import RiskCheckerDashboard from './RiskCheckerDashboard'
import Documentation from './Documentation';

const drawerWidth = 230;



export default function DashContainer({ userFirebase, goToPage, signOut, isAuthenticated, isEmailVerified, setIsAuthenticated, ui }) {
  // console.log("userFirebase, isAuthenticated, isEmailVerified, ", userFirebase, isAuthenticated, isEmailVerified)
  const [sidebar, setSidebar] = useState("GameHome")
  const [emailOpen, setEmailOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [deepDive, setDeepDive] = useState({});
  const [updateDash, setUpdateDash] = useState(false);
  const [showMessages, setShowMessages] = useState(null);
  const [updater, setUpdater] = useState(0);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [gameData, setGameData] = useState(null)
  const [allCampaigns, setAllCampaigns] = useState(null)
  const [dataSnackExamples, setDataSnackExamples] = useState(null)
  const [dataSnackGames, setDataSnackGames] = useState(null)
  const [campaignAnalysis, setCampaignAnalysis] = useState(null)
const [apiKey, setApiKey] = useState(null)

  const [gameDetails, setGameDetails] = useState(null)


  const [searchParams, setSearchParams] = useSearchParams();
  const q = searchParams.get('q');
  const id = searchParams.get('id')


  const typewriterAudioRef = useRef(null)
  const chaosAudioRef = useRef(null)
  const sentMessageAudioRef = useRef(null)
  const waitingAudioRef = useRef(null)

  const soundEffectObj = useRef({
    typewriterAudioRef,
    chaosAudioRef,
    sentMessageAudioRef,
    waitingAudioRef
  })


  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    console.log("dash container use effect")
    if (isAuthenticated ) {
      getCurrentUser()
     
    }

    
    console.log("game id", q)
    console.log("user id", id)

    getSharedGame(q, id)
    getCampaigns()

  }, [isAuthenticated])

  useEffect(() => {
    typewriterAudioRef.current.load()
    chaosAudioRef.current.load()
    sentMessageAudioRef.current.load()
    waitingAudioRef.current.load()



  }, [])

const createNewAPIKey = () =>{
  axios.get(`/api/v1/createApiKey`)
  .then(response => {
      console.log("apiKey", response.data)
      setApiKey(response.data)

  }).catch(error => {
      // need to figure out way to handle user swiping away from screen and waiting
      console.log("createApiKey error:", error)
  })
}


  const getGameResearch = (campaign) => {

    axios.post('/api/v1/getGameResearch', campaign)
      .then(response => {
        console.log(response.data)
        if (response.data === null) {
          setShowMessages({
            alertType: "success",
            title: "No Analysis Started",
            message: "Please analyze data first.",
          })
          return
        }
        setCampaignAnalysis(response.data)
        setSidebar("CampaignAnalysis")

      }).catch(error => {

        console.log("error", error)
        setShowMessages({
          alertType: "error",
          title: "Problem Getting SnackSurvey Analysis Data",
          message: "Please try back at a later time.",
        })

      });
  }

  const analyzeCampaign = (campaign) => {

    if (!campaign.campaignStats) {
      setShowMessages({
        alertType: "success",
        title: "No Responses Received",
        message: "Please wait until you have have at least 1 response to analyze",
      })
      return
    }

    setShowMessages({
      alertType: "info",
      title: "Working on analysis",
      message: "Process can take up to 1 minute based on the amount of data available",
      timeInSecs: 90
    })
    axios.post('/api/v1/createGameResearch', campaign)
      .then(response => {
        setShowMessages({
          alertType: "success",
          title: "Success",
          message: "Analysis Created",
        })
        // add to array as campaignAnalysis accepts an array
        let temp = []
        temp.push(response.data)
        setCampaignAnalysis(temp)
        setSidebar("CampaignAnalysis")

        console.log(response.data)


      }).catch(error => {

        console.log("error", error)
        setShowMessages({
          alertType: "error",
          title: "Error Analyzing SnackSurvey",
          message: "Please try back at a later time.",
        })

      });
  }

  //handleDownload from files
  const handleDownload = async (someContent) => {
    try {
      console.log("calling handleDownload", someContent)
      const response = await axios.post(`/api/v1/downloadDataCSV`, someContent)
      // download link
      console.log(response.data)
      try {
        console.log("calling axiosS3")
        const res = await axiosS3({ url: response.data.link, method: 'GET', responseType: 'blob' })

        console.log("called s3", res)
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.data.filename);
        document.body.appendChild(link);
        link.click();

      } catch (error) {
        console.log(error.res.data);
      }


    } catch (error) {
      console.log(error);

    }

  };


  const createCampaign = (campaign) => {
    setShowMessages({
      alertType: "info",
      title: "Submitted",
      message: "Building campaign now...",
      timeInSecs: 100
    })
    // create ref filename for image
    let filename = ""
    campaign.fileInfo.forEach(async (uploadedFile) => {
      filename = `${crypto.randomUUID()}-${uploadedFile.name}`
    })

    handleImageUpload(campaign.fileInfo, filename)
    campaign.imageFilename = filename

    axios.post('/api/v1/createCampaign', campaign)
      .then(response => {
        setShowMessages({
          alertType: "success",
          title: "Success",
          message: "SnackSurvey Created",
        })
        // get new client campaign
        getCampaigns()
        // eventually change this to only get dataSnack initiated campaigns
        setSidebar("AllCampaigns")
        console.log(response.data)


      }).catch(error => {
        console.log("error", error)

        if (!error.response) {
          setShowMessages({
            alertType: "error",
            title: "Error Creating SnackSurvey",
            message: "Please try back at a later time.",
          })
          return
        }

        switch (error.response.data.error) {
          case "bad url":
            setShowMessages({
              alertType: "error",
              title: "Url Is Not Valid",
              message: "Please check url and try again",
            })
            break;
          case "no campaign name":
            setShowMessages({
              alertType: "error",
              title: "No Campaign Name",
              message: "Please add a campaign name and try again",
            })
            break;
          case "no client challenge":
            setShowMessages({
              alertType: "error",
              title: "No Challenge",
              message: "Please add a campaign challenge and try again",
            })
            break;
          case "no client background info":
            setShowMessages({
              alertType: "error",
              title: "No Editorial / Background Content",
              message: "Please add editorial or background content and try again",
            })
            break;
          default:
            setShowMessages({
              alertType: "error",
              title: "Error Creating SnackSurvey",
              message: "Please try back at a later time.",
            })

        }


      });
  }

  const handleImageUpload = (e, filename) => {

    if (e.length === 0) {
      console.log("file array not long enough")
      return
    }

    try {

      e.forEach(async (uploadedFile) => {
        console.log("uploading file", uploadedFile)
        const response = await axios.get(`/api/v1/getPreSignedURL?filename=${filename}&status=start`);
        console.log("presign ", response.data)
        reader(uploadedFile, async (err, res) => {
          const base64Response = await fetch(res);
          const blob = await base64Response.blob();
          try {
            const response1 = await axiosS3.put(response.data.URL, blob, {
              headers: {
                'Content-Type': uploadedFile.type,
                'Access-Control-Allow-Origin': '*',
              },
            },)
            console.log("presign - response1 ", response1)
            try {
              const response2 = await axios.get(`/api/v1/getPreSignedURL?filename=${filename}&status=done`);
              console.log("presign - response2 ", response2)
            } catch (error) {
              console.log(error)
              setShowMessages({
                alertType: "error",
                title: "There was an error uploading file",
                message: "Please try back at a later time",
              })

            }
          } catch (error) {
            console.log(error)
            setShowMessages({
              alertType: "error",
              title: "There was an error uploading file",
              message: "Please try back at a later time",
            })
          }
        });
      })


    } catch (error) {
      console.log("pre-sign error", error);
      setShowMessages({
        alertType: "error",
        title: "There was an error uploading file",
        message: "Please try back at a later time",
      })


    }
  }

  const reader = (file, callback) => {
    const fr = new FileReader();
    fr.onload = () => callback(null, fr.result);
    fr.onerror = (err) => callback(err);
    fr.readAsDataURL(file);
  }

  const startNewGame = (gameId, id) => {

    // needs to be new ThreadId
    console.log("gameId", gameId)
    console.log("userIdentifier", id)
    if (id === null) {
      id = ""
    }
    setSidebar("GameLoading")

    let tempGameMessage = []

    axios.post(`/api/v1/startGame?gameId=${gameId}&id=${id}`)
      .then(response => {

        console.log("startgame details:", response.data)
        setGameDetails({
          gameId: response.data.gameMessage.gameId,
          threadId: response.data.gameMessage.threadId,
          returnUrl: response.data.campaign.returnUrl
        })
        // returns an game message which is added to game data array
        tempGameMessage.push(response.data.gameMessage)
        setGameData(tempGameMessage)
        setShowMessages(null)
        setTimeout(() => {
          setSidebar("PlayGame")
        }, 3000)

        console.log("game data from server:", tempGameMessage)


      }).catch(error => {
        // need to figure out way to handle user swiping away from screen and waiting
        console.log(error)
        setSidebar("ForBrands")
        setShowMessages({
          alertType: "error",
          title: "Problem Starting Game",
          message: "Please check link and try again.",
        })


      })
  }

  const continueGame = (gameId) => {

    let lastMessageSerial = localStorage.getItem(`gameId-${gameId}`);
    // check for empty gameId
    if (lastMessageSerial === null) {
      setShowMessages({
        alertType: "success",
        title: "No Saved Progress",
        message: "Please start a new game to play",
      })
      setSidebar("GameHome")
      return
    }

    let lastMessage = JSON.parse(lastMessageSerial)

    console.log("game message struct", lastMessage)

    setSidebar("PlayGame")
    setGameDetails({
      gameId: lastMessage.gameId,
      threadId: lastMessage.threadId,
    })

    let temp = []
    temp.push(lastMessage)
    setGameData(temp)

  }



  const handleGameInput = (gameMessage) => {
    // check to see if game ended

    if (gameData[gameData.length - 1].isGameFinished) {
      setShowMessages({
        alertType: "success",
        title: "Play Another Game?",
        message: "Choose any game below to play",
      })
      setSidebar("GameHome")
      return
    }
    console.log("game message struct", gameMessage)
    // make sure it's in play game screen
    setSidebar("PlayGame")
    let temp = [...gameData];

    // add user content to local GameData array
    if (temp.length !== 0) {
      temp.push(gameMessage)
    }
    // update screen w/ user input
    setGameData(temp)


    axios.post('/api/v1/handleGameInput', gameMessage)
      .then(response => {
        temp.push(response.data)
        setGameData(temp)
        console.log("game data from server:", temp)
        // determine if game is over and points
        // populate gameDetails
        if (response.data.isGameFinished) {
          getScore(response.data)
        } else {
          console.log("turned off chaos bot")
          //   handleCreateChaos({
          //     messageId: crypto.randomUUID(),
          //     message: response.data.message,
          //     messageType: "assistant",
          // }, temp)
        }



      }).catch(error => {
        // need to figure out way to handle user swiping away from screen and waiting
        console.log(error)

        if (!error.response) {
          setShowMessages({
            alertType: "error",
            title: "Problem with game response",
            message: "Please try again later",
          })
          setSidebar("GameHome")
          return
        }

        if (error.response.data.error === "start a new game") {
          setShowMessages({
            alertType: "success",
            title: "Play Another Game?",
            message: "Choose any game below to play",
          })
          setSidebar("GameHome")
          return
        }

      })
  }

  const getScore = (gameMessage) => {

    axios.post('/api/v1/checkIfGameIsFinished', gameMessage)
      .then(response => {
        // determine if game is over and points
        console.log("score details", response.data)
        setGameDetails(response.data)
        console.log("score and if game is finished", response.data)
      }).catch(error => {
        console.log("checkIfGameIsFinished", error)

      })
  }

  const handleCreateChaos = (gameMessage, temp) => {
    console.log("game message struct", gameMessage)


    setSidebar("PlayGame")
    axios.post('/api/v1/handleCreateChaos', gameMessage)
      .then(response => {
        if (response.data.randomStatus) {
          console.log("limiter function denied friend bot")
          return
        }

        if (gameData[gameData.length - 1].messageType === "user") {
          console.log("not showing chaos bot as user already added input")
          return
        }

        temp.push(response.data)
        setGameData(temp)
        console.log("game data from server:", temp)


      }).catch(error => {
        // need to figure out way to handle user swiping away from screen and waiting
        console.log("chaos creator timed out")
        console.log(error)


      })
  }

  const getCampaigns = () => {

    axios.get('/api/v1/getCampaigns', {
      timeout: 1000 * 60 * 4
    })
      .then(response => {

        setAllCampaigns(response.data)
        setDataSnackGames(response.data)
        console.log("game campaigns from server:", response.data)


      }).catch(error => {
        // need to figure out way to handle user swiping away from screen and waiting

        console.log(error)
        setSidebar("GameHome")
        setShowMessages({
          alertType: "error",
          title: "Problem getting campaigns",
          message: "Please try back at a later time",
        })

      })
  }

  const getSharedGame = (queryId, id) => {
    // handle shared game
    if (queryId) {
      try {
        let convert = atob(queryId)
        startNewGame(convert, id)
      } catch {
        setSidebar("ForBrands")
        setShowMessages({
          alertType: "error",
          title: "Problem getting game details",
          message: "Please try back at a later time",
        })
      }

    }
  }




  const getAllDataSnackGames = (gameType) => {

    axios.get(`/api/v1/getAllDataSnackGames?gameType=${gameType}`, {
      timeout: 1000 * 60 * 4
    })
      .then(response => {
        switch (gameType) {
          case "EXAMPLE_SURVEY":
            setDataSnackExamples(response.data)
            break;
          case "DATASNACK_GAME":
            setDataSnackGames(response.data)
            break;
          default:
            setDataSnackExamples(response.data)

        }

        console.log("game campaigns from server:", response.data)


      }).catch(error => {
        // need to figure out way to handle user swiping away from screen and waiting
        console.log(error)

      })
  }


  const getCurrentUser = async () => {
    try {
      console.log("in fetch data")
      const response = await axios.get('/api/v1/getUser', {
        timeout: 10000
      });
      console.log("User:", response.data)
      setUser(response.data)


    } catch (error) {
      console.log(error)
      setShowMessages({
        alertType: "error",
        title: "Problem getting user details",
        message: "Please try back at a later time",
      })
    }
  }

  // master delete
  const handleDelete = async (gameId) => {

    try {
      const response = await axios.delete(`/api/v1/deleteCampaign?gameId=${gameId}`);
      console.log("delete", response.data)
      setShowMessages({
        alertType: "success",
        title: "Success! ",
        message: "SnackSurvey deleted.",
      })
      getCampaigns()

    } catch (error) {
      console.log(error);
      setShowMessages({
        alertType: "error",
        title: "Error Deleting SnackSurvey",
        message: "Please try back at a later time",
      })

    }
  }




  const handleClose = () => {
    setOpen(false)
  }

  const handleEmailClose = () => {
    setEmailOpen(false)
  }

  const handleSubmit = (register) => {


    console.log("data from submit", register)

    axios.post(window.$ip + '/api/v1/addUser', register)
      .then(response => {
        setOpen(false)
        setIsAuthenticated(true)
        navigate("/")
        setUser(response.data)

      }).catch(error => {

        console.log("error", error)
      });
  }

  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: function (authResult, redirectUrl) {
        console.log("auth result:", authResult)
        // check if new signup
        if (authResult.additionalUserInfo.isNewUser) {
          let register = {
            fullName: authResult.user.multiFactor.user.displayName,
            email: authResult.user.multiFactor.user.email,
            userId: authResult.user.multiFactor.user.uid,
            accountValidated: authResult.user.multiFactor.user.emailVerified
          }
          handleSubmit(register)
          setShowMessages({
            alertType: "success",
            title: "Done",
            message: "Successfully signed up.",
          })
          setSidebar("AllCampaigns")

        } else {
          setIsAuthenticated(true)
          navigate("/")
          setShowMessages({
            alertType: "success",
            title: "Done",
            message: "Successfully signed in.",
          })
        }

        // User successfully signed in.
        // Return type determines whether we continue the redirect automatically
        // or whether we leave that to developer to handle.
        return false;
      },
      // uiShown: function () {
      //     // The widget is rendered.
      //     // Hide the loader.
      //     document.getElementById('loader').style.display = 'none';
      // }
    },
    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    signInFlow: 'popup',
    // signInSuccessUrl: '/dashboard',
    signInOptions: [
      // Leave the lines as is for the providers you want to offer your users.
      // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      // firebase.auth.GithubAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      // firebase.auth.PhoneAuthProvider.PROVIDER_ID
    ],
    // Terms of service url.
    // tosUrl: '<your-tos-url>',
    // Privacy policy url.
    // privacyPolicyUrl: '<your-privacy-policy-url>'
  };

  const renderDashScreen = () => {
    console.log("in render...", sidebar)
    switch (sidebar) {




      case "Contact":
        return (
          <>
            <Contact
              setShowMessages={setShowMessages}
              dashData={deepDive}
              handleScreenChangeClick={handleScreenChangeClick} updater={updater} />
          </>
        )

      case "CampaignAnalysis":
        return (
          <>
            <CampaignAnalysis
              handleDownload={handleDownload}
              content={campaignAnalysis}
              handleScreenChangeClick={handleScreenChangeClick} />
          </>
        )

        case "Documentation":
          return (
            <Documentation />
          )



      case "Feedback":
        return (
          <>
            <Feedback

              handleDelete={handleDelete}
              handleDownload={handleDownload}
              setShowMessages={setShowMessages}
              handleScreenChangeClick={handleScreenChangeClick} />
          </>
        )

      case "Admin":
        return (
          <>
            <Admin
              isAuthenticated={isAuthenticated}
              handleDelete={handleDelete}
              apiKey={apiKey}
              setApiKey={setApiKey}
              createNewAPIKey={createNewAPIKey}
              handleDownload={handleDownload}
              setShowMessages={setShowMessages}
              handleScreenChangeClick={handleScreenChangeClick} />
          </>
        )

      case "ForBrands":
        return (
          <>
            <ForBrands
              getAllDataSnackGames={getAllDataSnackGames}
              openLogin={openLogin}
              allGames={dataSnackExamples}
              continueGame={continueGame}
              startNewGame={startNewGame}
              setShowMessages={setShowMessages}
              handleScreenChangeClick={handleScreenChangeClick}
            />
          </>
        )

      case "GameHome":
        return (
          <>
            <GameHome
              handleScreenChangeClick={handleScreenChangeClick}
              allGames={dataSnackGames}
              getAllDataSnackGames={getAllDataSnackGames}
              continueGame={continueGame}
              startNewGame={startNewGame}

            />
          </>
        )

        case "RiskCheckerDashboard":
          return (
            <RiskCheckerDashboard user={user} setShowMessages={setShowMessages} setUser={setUser} isAuthenticated={isAuthenticated}/>
          )

      case "PlayGame":
        return (
          <>
            <PlayGame

              gameData={gameData}
              soundEffectObj={soundEffectObj.current}
              gameDetails={gameDetails}
              handleGameInput={handleGameInput}
              handleCreateChaos={handleCreateChaos}
              handleScreenChangeClick={handleScreenChangeClick}
              setShowMessages={setShowMessages}
            />
          </>
        )


      case "AllCampaigns":
        return (
          <>
            <AllCampaigns
              handleDelete={handleDelete}
              getCampaigns={getCampaigns}
              allCampaigns={allCampaigns}
              createCampaign={createCampaign}
              analyzeCampaign={analyzeCampaign}
              getGameResearch={getGameResearch}
              handleDownload={handleDownload}
              startNewGame={startNewGame}
              user={user}


            />
          </>
        )

      case "GameLoading":
        return (
          <>
            <GameLoading />
          </>
        )

      case "Privacy":
        return (
          <>
            <Privacy
              handleScreenChangeClick={handleScreenChangeClick} />
          </>
        )

      case "TOS":
        return (
          <>
            <TOS
              handleScreenChangeClick={handleScreenChangeClick} />
          </>
        )

      default:
        return (
          <>
            <ForBrands

              openLogin={openLogin}

              continueGame={continueGame}
              startNewGame={startNewGame}
              setShowMessages={setShowMessages}
              handleScreenChangeClick={handleScreenChangeClick}
            />
          </>
        )
    }

  }

  const handleScreenChangeClick = (data) => {
    console.log("handleScreenChangeClick:", data)
    setUpdater(Math.floor(Math.random() * 10000))
    setDeepDive(data)
    setSidebar(data.selectedIndex)
    if (data.updateDash) {
      setUpdateDash(!updateDash)
    }
    if (data.contentMessage) {
      console.log("data.contentMessage: ", data.contentMessage)
      setTimeout(() => {
        setShowMessages(data.contentMessage)
      }, 250)


    }


  }



  const openLogin = () => {
    setOpen(true)
    ui.start('#firebaseui-auth-container', uiConfig);
    setMobileOpen(false)
  }



  const NestedList = () => {


    return (
      // <ThemeProvider theme={theme}>
      <List
        sx={{
          width: '100%', maxWidth: 360, bgcolor: '#212325', '& .Mui-selected': {
            backgroundColor: '#37383a',
          },
        }}
        selected
        component="nav"
        aria-labelledby="nested-list-subheader"

      >

        {/* <ListSubheader component="div" id="nested-list-subheader" sx={{ backgroundColor: "#212325", marginTop: 2, marginBottom: 2, fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 700 }, lineHeight: '90%', color: "#ffffff" }}>
          Home
        </ListSubheader> */}

        {/* <ListItemButton
          sx={{ pl: 4 }}
          selected={sidebar === "ForBrands"}
          onClick={() => {
            setSidebar("ForBrands")
            setMobileOpen(false)
          }}
        >

          <DataObjectIcon sx={{ fontSize: 20, marginRight: 2, color: "#fcfdfd", opacity: 0.8 }} />

          <ListItemText
            disableTypography
            primary={<Typography sx={{ display: 'flex', fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 400 }, color: "#fcfdfd" }}>DataSnack</Typography>}
          />
        </ListItemButton> */}

        {/* <ListItemButton
          sx={{ pl: 4 }}
          selected={sidebar === "GameHome"}
          onClick={() => {
            setSidebar("GameHome")
            setMobileOpen(false)
          }}
        >

          <GamesIcon sx={{ fontSize: 20, marginRight: 2, color: "#fcfdfd", opacity: 0.8 }} />

          <ListItemText
            disableTypography
            primary={<Typography sx={{ display: 'flex', fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 400 }, color: "#fcfdfd" }}>Play Games</Typography>}
          />
        </ListItemButton> */}


        <ListSubheader component="div" id="nested-list-subheader" sx={{ backgroundColor: "#212325", marginTop: 2, marginBottom: 2, fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 700 }, lineHeight: '90%', color: "#ffffff" }}>
          Home
        </ListSubheader>

     

        {/* <ListItemButton sx={{ pl: 4 }}
          selected={sidebar === "RiskCheckerDashboard"}
          onClick={() => {
            // if (!isAuthenticated) {
            //   setShowMessages({
            //     alertType: "error",
            //     title: "Not Logged In",
            //     message: "Please create an account of log in to view campaigns",
            //   })
            //   setMobileOpen(false)
            //   return
            // }
            setSidebar("RiskCheckerDashboard")
            setMobileOpen(false)

          }}>

          <SpaceDashboardIcon sx={{ fontSize: 20, marginRight: 2, color: "#fcfdfd", opacity: 0.8 }} />

          <ListItemText
            disableTypography
            primary={<Typography sx={{ display: 'flex', fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 400 }, color: "#fcfdfd" }}>SnackRisks</Typography>}
          />
        </ListItemButton> */}

        <ListItemButton sx={{ pl: 4 }}
          selected={sidebar === "AllCampaigns"}
          onClick={() => {
            if (!isAuthenticated) {
              setShowMessages({
                alertType: "error",
                title: "Not Logged In",
                message: "Please create an account of log in to view campaigns",
              })
              setMobileOpen(false)
              return
            }
            setSidebar("AllCampaigns")
            setMobileOpen(false)

          }}>

          <SpaceDashboardIcon sx={{ fontSize: 20, marginRight: 2, color: "#fcfdfd", opacity: 0.8 }} />

          <ListItemText
            disableTypography
            primary={<Typography sx={{ display: 'flex', fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 400 }, color: "#fcfdfd" }}>SnackSurveys</Typography>}
          />
        </ListItemButton>

        <ListSubheader component="div" id="nested-list-subheader" sx={{ backgroundColor: "#212325", marginTop: 2, marginBottom: 2, fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 700 }, lineHeight: '90%', color: "#ffffff" }}>
          Admin
        </ListSubheader>
        
        {/* <ListItemButton sx={{ pl: 4 }}
          selected={sidebar === "Documentation"}
          onClick={() => {
            setSidebar("Documentation")
            setMobileOpen(false)

          }}>

          <DescriptionIcon sx={{ fontSize: 20, marginRight: 2, color: "#fcfdfd", opacity: 0.8 }} />

          <ListItemText
            disableTypography
            primary={<Typography sx={{ display: 'flex', fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 400 }, color: "#fcfdfd" }}>Documentation</Typography>}
          />
        </ListItemButton> */}

        <ListItemButton sx={{ pl: 4 }}
          selected={sidebar === "Admin"}
          onClick={() => {
            if (!isAuthenticated) {
              setShowMessages({
                alertType: "error",
                title: "Not Logged In",
                message: "Please create an account of log in to Admin",
              })
              setMobileOpen(false)
              return
            }
            setSidebar("Admin")
            setMobileOpen(false)

          }}>

          <AccountBoxIcon sx={{ fontSize: 20, marginRight: 2, color: "#fcfdfd", opacity: 0.8 }} />

          <ListItemText
            disableTypography
            primary={<Typography sx={{ display: 'flex', fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 400 }, color: "#fcfdfd" }}>Profile</Typography>}
          />
        </ListItemButton>

        {/* <ListItemButton sx={{ pl: 4 }}
          selected={sidebar === "Feedback"}
          onClick={() => {
            setSidebar("Feedback")
            setMobileOpen(false)

          }}>

          <FeedbackIcon sx={{ fontSize: 20, marginRight: 2, color: "#fcfdfd", opacity: 0.8 }} />

          <ListItemText
            disableTypography
            primary={<Typography sx={{ display: 'flex', fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 400 }, color: "#fcfdfd" }}>Submit Feedback</Typography>}
          />
        </ListItemButton> */}

        {/* <ListItemButton sx={{ pl: 4 }}
          selected={sidebar === "Contact"}
          onClick={() => {
            setSidebar("Contact")
            setMobileOpen(false)

          }}>

          <ContactPageIcon sx={{ fontSize: 20, marginRight: 2, color: "#fcfdfd", opacity: 0.8 }} />

          <ListItemText
            disableTypography
            primary={<Typography sx={{ display: 'flex', fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 400 }, color: "#fcfdfd" }}>Contact Us</Typography>}
          />
        </ListItemButton> */}

        {!isAuthenticated && <ListItemButton sx={{ pl: 4 }}

          onClick={openLogin}>

          <LogoutIcon sx={{ fontSize: 20, marginRight: 2, color: "#fcfdfd", opacity: 0.8 }} />

          <ListItemText
            disableTypography
            primary={<Typography sx={{ display: 'flex', fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 400 }, color: "#fcfdfd" }}>Sign in / Sign up</Typography>}
          />
        </ListItemButton>}

        {isAuthenticated && <ListItemButton sx={{ pl: 4 }}

          onClick={() => {
            setIsAuthenticated(false)
            localStorage.removeItem('token');
            setShowMessages({
              alertType: "success",
              title: "Done",
              message: "Successfully signed out.",
            })
            setMobileOpen(false)
            setApiKey(null)
            signOut()
            setSidebar("ForBrands")

          }}>

          <LogoutIcon sx={{ fontSize: 20, marginRight: 2, color: "#fcfdfd", opacity: 0.8 }} />

          <ListItemText
            disableTypography
            primary={<Typography sx={{ display: 'flex', fontSize: 12, typography: { fontFamily: "Roboto", fontWeight: 400 }, color: "#fcfdfd" }}>Logout</Typography>}
          />
        </ListItemButton>}




      </List>
      // </ThemeProvider>

    );
  }


  return (

    <Box sx={{ display: 'flex' }}>
      <Dialog
        open={open}
        fullWidth={true}
        keepMounted={true}
        sx={{
          //You can copy the code below in your theme
          // background: "#000", // Change to 'red' for example to make it more visible
          "& .MuiPaper-root": {
            background: "transparent", // Change to 'red' for example to make it more visible
            boxShadow: "none",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent" // Try to remove this to see the difference
          }
        }}
        onClose={handleClose}>

        <div style={{
          fullWidth: true,
        }} id="firebaseui-auth-container"></div>


      </Dialog>

      {!isEmailVerified && (
        <Dialog
          open={emailOpen}
          fullWidth={true}


          onClose={handleEmailClose}>
          <DialogTitle sx={{ typography: { fontFamily: "Roboto", fontWeight: 900 }, fontSize: 22, color: "#212325" }}>
            Email verification needed
          </DialogTitle>

          <DialogContent>
            <Typography sx={{ fontSize: 12, typography: { fontFamily: "CourierPrime-Regular" }, color: "#212325" }} fontStyle={"normal"} >
              Please check your recent emails for a verification link. Please click SEND to receive a new verification email.
            </Typography>

          </DialogContent>

          <DialogActions style={{ marginTop: 2 }}>
            <Button sx={{ typography: { fontFamily: "CourierPrime-Regular" }, fontSize: 12, color: "#212325" }}
              onClick={handleEmailClose}>Cancel</Button>
            <Button sx={{ typography: { fontFamily: "CourierPrime-Regular" }, fontSize: 12, color: "#212325" }}
              onClick={() => {

                firebase.auth().currentUser.sendEmailVerification()
                handleEmailClose()
              }}>Send</Button>
          </DialogActions>

        </Dialog>
      )}
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}

      >
        <Toolbar sx={{ backgroundColor: "#ffffff" }}>


          <Header setSidebar={setSidebar} user={user}
            handleDrawerToggle={handleDrawerToggle}
            setShowMessages={setShowMessages}
            handleScreenChangeClick={handleScreenChangeClick}

            isAuthenticated={isAuthenticated} />

        </Toolbar>
      </AppBar>


      <Drawer

        sx={{
          // display: { xs: 'block', sm: 'none' },

          '& .css-10hburv-MuiTypography-root.css-10hburv-MuiTypography-root': {
            fontFamily: "Roboto", fontWeight: 400,
            fontSize: 12,
            color: "#212325"
          },
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: "#212325",

            // fontFamily: "Roboto", fontWeight: 300,

          },
        }}
        // variant="permanent"
        variant={true ? "temporary" : "permanent"}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}

        anchor="left"


      >
        <Toolbar />
        <Divider />
        <NestedList />

      </Drawer>
      <Box
        // component={'Dashboard'}
        sx={{
          flexGrow: 1,
          backgroundColor: '#212325',
          //   p: 3 
        }}
      >
        <Toolbar />
        {showMessages && <ShowMessages msgObj={showMessages} setShowMessages={setShowMessages} />}

        {renderDashScreen()}

        {/* load audio for PlayGame */}
        <audio ref={typewriterAudioRef} preload={"auto"}>
          <source src={typewriterSound} type="audio/mpeg" />
        </audio>

        <audio ref={chaosAudioRef} preload={"auto"}>
          <source src={chaos} type="audio/mpeg" />
        </audio>


        <audio ref={sentMessageAudioRef} preload={"auto"}>
          <source src={sentMessage} type="audio/mpeg" />
        </audio>
        <audio ref={waitingAudioRef} preload={"auto"}>
          <source src={waitingSound} type="audio/mpeg" />
        </audio>

      </Box>
    </Box>

  );
}

