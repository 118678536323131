import * as React from 'react';
import {
    Button, Card, CardHeader, CardMedia, Box,
    CardContent, CardActions, IconButton, Typography
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';

import SocialShare from './SocialShare';


export default function HeroCard({ image, title, content, height, cta }) {


    return (
        <Card >

            <CardMedia
                component="img"
                height="200px"
                src={image}
                // sx={{
                //     background: "#499dd7",
                //     opacity: 0.5
                // }}
                // sx={{objectPosition: "50% 0%"}}
                // image={gameData.imageUrl}
                alt="Hero Card"
            />
            <CardContent 
            
            sx={{ height: height, direction: "column", alignContent: "center" }}
            
            >

                <Typography marginTop={1} sx={{
                   
                    textAlign: "left",
                   
                    typography: { fontFamily: "Roboto", fontWeight: 600 },
                    fontSize: 24,
                    lineHeight: "110%",

                    color: "#212325"
                }} >
                    {title}
                </Typography>

                <Typography marginTop={1} sx={{
                    textTransform: "none",

                    textAlign: "left",
                   
                    typography: { fontFamily: "Roboto", fontWeight: 400 },
                    fontSize: 14,
                    lineHeight: "110%",

                    color: "#37383a"
                }} >
                    {content}
                </Typography>

                {/* <Typography marginTop={1} sx={{
                    textTransform: "none",

                    textAlign: "left",
                   
                    typography: { fontFamily: "Roboto", fontWeight: 500 },
                    fontSize: 16,
                    lineHeight: "110%",

                    color: "#499dd7"
                }} >
                    {cta}
                </Typography> */}
            </CardContent>

            <Box sx={{
                backgroundColor: "#499dd7",
                minHeight: "5px",
                alignSelf: "flex-end"
            }} />

        </Card>
    );
}