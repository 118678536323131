import React, { useState } from 'react'

import { Stack, Typography } from "@mui/material";
import { DropzoneArea } from "mui-file-dropzone";





const FileUploader = ({ setFileInfo }) => {

  return (
    <div >
      <Stack sx={{ padding: 0 }}>
      
        {/* <Typography marginTop={3} marginBottom={4} sx={{ fontSize: 16, typography: { fontFamily: "Roboto", fontWeight: 400 }, lineHeight: '90%', color: "#212325" }} fontStyle={"normal"} component="div">
          Images (JPG & PNG) must be less than 1 megabyte in size
        </Typography> */}
        <DropzoneArea filesLimit={1}
          maxFileSize={1048576}
        
          acceptedFiles={['image/*']}
          dropzoneText={"Drag and drop an image or avatar for the survey here"}
          onChange={(e) => {
            setFileInfo(e)
          }}

          onDelete={() => {
            setFileInfo({})
          }} />


      </Stack>
    </div>
  )
}


export default FileUploader