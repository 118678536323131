import * as React from 'react';
import {
    Button, Card, CardHeader, CardMedia, Box, Stack,
    CardContent, CardActions, IconButton, Typography
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';

import SocialShare from './SocialShare';


export default function DividerCard({ image, title, content, tryDemo }) {

    // window.$isMobile ?
    return (

        <Stack
            direction={window.$isMobile ? "column" :"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
                backgroundColor: "#ffffff",
                borderRadius: 2.5,
                width: "90%",
                boxShadow: 5,
                direction: "row",
                overflow: "hidden"

                // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23262f71' fill-opacity='0.05'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,

            }}>

            <Stack width={window.$isMobile ? "100%" :"60%"} direction={"column"} alignItems={"flex-start"}
                sx={{ overflow: "hidden" }}>
                <img src={image} alt="Location Image" style={{ width: window.$isMobile ? "100%" : "350px",  }} />
            </Stack>



            <Stack direction={"column"} padding={3} marginLeft={window.$isMobile ? 0 : 5}>


                <Typography sx={{

                    textAlign: "left",

                    typography: { fontFamily: "Roboto", fontWeight: 600 },
                    fontSize: 24,
                    lineHeight: "110%",

                    color: "#212325"
                }} >
                    {title}
                </Typography>

                <Typography marginTop={1} sx={{

                    textAlign: "left",

                    typography: { fontFamily: "Roboto", fontWeight: 400 },
                    fontSize: 14,
                    lineHeight: "110%",

                    color: "#37383a"
                }} >
                    {content}
                </Typography>
            </Stack>

            <Stack padding={3} marginRight={window.$isMobile ? 0 : 5}>
                <Button 
            onClick={tryDemo}
                sx={{ backgroundColor: "#006ec2", width: "200px", height: "45px" }}>
                    <Typography sx={{
                        textTransform: "none",
                        typography: { fontFamily: "Roboto", fontWeight: 700 },
                        fontSize: 14,
                        color: "#ffffff"
                    }} >
                        Try It Now!
                    </Typography>
                </Button>
            </Stack>
        </Stack>

    );
}