import { Routes, Route, Outlet, Link, Navigate, BrowserRouter } from "react-router-dom";
// import Home from './Screens/Home';
// import HomeTest from "./Screens/Home";
import React, { useState, useEffect } from "react";
import axios from './Login/axiosConfig'
import "./firebaseui.css"
import DashContainer from "./Screens/DashContainer";
import firebase from 'firebase/compat/app';
import ComingSoon from "./Screens/ComingSoon";
import './scss/fonts/embedFonts.css'
import RiskCheckerDashboard from "./Screens/RiskCheckerDashboard";
import Documentation from "./Screens/Documentation";

const ProtectedRoute = ({ isAuthenticated, children }) => {


  if (!isAuthenticated) {

    return <Navigate to="/" replace />;
  }

  return children;
};


const App = ({ app, ui }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const [user, setUser] = useState({})



  const signOut = () => {
    app.signOut()
  }

  useEffect(() => {
    console.log("App.js useEffect called w/ listener")
    firebase.auth().onIdTokenChanged((user) => {
      if (user) {
        setUser(user)
        setIsAuthenticated(true)
        console.log("user", user)

        window.$tokenRefreshTime = user.multiFactor.user.auth.currentUser.stsTokenManager.expirationTime
        console.log("expiration time", window.$tokenRefreshTime)
        console.log("is expired", user.multiFactor.user.auth.currentUser.stsTokenManager.isExpired)
        localStorage.setItem('token', user.multiFactor.user.accessToken)
        console.log("USER ID:", user.multiFactor.user.uid)
        if (user.multiFactor.user.emailVerified) {
          setIsEmailVerified(true)
        }

      } else {
        console.log("Problem getting token")
      }
    })

  }, [])



  return (


    <BrowserRouter>
     
     <Routes>
        
          <Route path="/" element={<DashContainer user={user} isAbout={false}
            signOut={signOut} isAuthenticated={isAuthenticated} isEmailVerified={isEmailVerified} setIsAuthenticated={setIsAuthenticated} ui={ui}
          />} />
   
          <Route path="/snackRisk" element={<DashContainer user={user} isAbout={false} goToPage={"RiskCheckerDashboard"}
            signOut={signOut} isAuthenticated={isAuthenticated} isEmailVerified={isEmailVerified} setIsAuthenticated={setIsAuthenticated} ui={ui}
          />} />
  
          <Route path="/docs" element={<Documentation />} />
     

        {/* <Route path="/" element={<DashContainer  user={user} isAbout={false}
              signOut={signOut} isAuthenticated={isAuthenticated} isEmailVerified={isEmailVerified} setIsAuthenticated={setIsAuthenticated} ui={ui}
        />} /> */}

        {/* <Route path="/about" element={<DashContainer  user={user} isAbout={true}
              signOut={signOut} isAuthenticated={isAuthenticated} isEmailVerified={isEmailVerified} setIsAuthenticated={setIsAuthenticated} ui={ui}
        />} /> */}
        {/* <Route path="/dashboard"
          element={<ProtectedRoute isAuthenticated={isAuthenticated}>
            <DashContainer setIsAuthenticated={setIsAuthenticated}
             user={user}
              signOut={signOut} />
          </ProtectedRoute>} /> */}

        <Route path="*" element={<NoMatch />} />
      </Routes>
    </BrowserRouter>


  );
}

const NoMatch = () => {
  return (
    <div className='Container'>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;
