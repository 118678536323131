import React, { useState, useEffect, useRef } from 'react'
import axios from '../../Login/axiosConfig.js'

import {
    TextField, Divider, Backdrop, CircularProgress,
    Button, FormGroup, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Stack, Typography
} from '@mui/material';

import { convertDomainToValidURL } from '../../Helpers/Helpers';

const usageArray = [
    'Internal department',
    'Management',
    'External partners',
    'Customers',
    'Press'
]



const RiskChecker = ({ fcn, data, user, addRiskSiloData, globalRiskSiloId, setGlobalRiskSiloId, clientCompanies, globalClientCompanyId, setGlobalClientCompanyId }) => {
    const [targetRecipientGroup, setTargetRecipientGroup] = useState(usageArray[0])
    const [content, setContent] = useState("")
    const [isQuickCheck, setIsQuickCheck] = useState(false)
    

    const handleQuickCheck = (event) => {
        setIsQuickCheck(event.target.checked);
    };




    const handleSubmit = () => {
     
        const register = {
            content,
            isQuickCheck,
            riskSiloId: globalRiskSiloId,
            user,
            targetRecipientGroup,
            clientCompanyId: globalClientCompanyId

        }
        console.log("data from submit", register)
        fcn(register)

    }

    return (
        <Stack direction="column" sx={{
            backgroundColor: "#F5F7F8", borderRadius: 5,
            padding: 2.5,
            paddingTop: 5,
        }} >
           

            <Typography marginTop={0} sx={{ fontSize: 26, typography: { fontFamily: "Roboto", fontWeight: 900 }, lineHeight: '90%', color: "#006ec2" }} fontStyle={"normal"} component="div">
                SnackRisk
            </Typography>
            <Stack marginTop={3} marginBottom={10} spacing={0}>

            {clientCompanies && <FormControl
                    sx={{ marginBottom: 4 }}
                    fullWidth >
                    <InputLabel id="demo-simple-select-label">Client Companies</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={globalClientCompanyId}
                        label="Client Companies"
                        onChange={(event) => {
                            setContent("")
                            setGlobalClientCompanyId(event.target.value)

                        }}
                    >
                        {clientCompanies.map((e) => (
                            <MenuItem key={e.clientCompanyId} value={e.clientCompanyId}>{e.companyName}</MenuItem>
                        ))}


                    </Select>
                </FormControl>}

                {addRiskSiloData && <FormControl
                    sx={{ marginBottom: 4 }}
                    fullWidth >
                    <InputLabel id="demo-simple-select-label">Risk Silo</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={globalRiskSiloId}
                        label="Target Recipient"
                        onChange={(event) => {
                            setContent("")
                            setGlobalRiskSiloId(event.target.value)

                        }}
                    >
                        {addRiskSiloData.map((e) => (
                            <MenuItem key={e.riskSiloId} value={e.riskSiloId}>{e.riskSiloName}</MenuItem>
                        ))}


                    </Select>
                </FormControl>}



                {usageArray && <FormControl fullWidth >
                    <InputLabel id="demo-simple-select-label">Target Recipient</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={targetRecipientGroup}
                        label="Target Recipient"
                        onChange={(event) => {
                            setTargetRecipientGroup(event.target.value)

                        }}
                    >
                        {usageArray.map((e) => (
                            <MenuItem key={e} value={e}>{e}</MenuItem>
                        ))}


                    </Select>
                </FormControl>}

                <TextField
                    sx={{ marginBottom: 5 }}
                    autoFocus={false}
                    margin="dense"
                    onChange={(e) => {
                        setContent(e.target.value)
                    }}
                    id="description"
                    label="Content"
                    type="text"
                    fullWidth
                    multiline
                    value={content}
                    variant="standard"
                />

                {/* <FormGroup>

                    <FormControlLabel control={<Checkbox checked={quickCheck}
                        onChange={handleQuickCheck} />} label="Quick Check" />

                </FormGroup> */}


                <Stack width={"100%"} direction="row" spacing={2} marginTop={2} justifyContent={"flex-end"} >

                    <Button onClick={() => {
                        setContent("")
                        setTargetRecipientGroup(usageArray[0])


                    }}>Clear</Button>
                    <Button onClick={handleSubmit} >Submit</Button>
                </Stack>

            </Stack>



            {data && data.riskReport.risks && data.riskReport.risks.map((e) => (
                <Stack key={e.riskId}>
                    <Typography

                        sx={{
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 4,
                            textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 900 },
                            fontSize: window.$isMobile ? 28 : 28, lineHeight: '105%', color: "#212325"
                        }} fontStyle={"normal"}>
                        {e.description}


                    </Typography>
                    <Typography

                        paddingTop={2}
                        sx={{

                            textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 600 },
                            fontSize: window.$isMobile ? 16 : 16, lineHeight: '105%', color: "#006ec2"
                        }} fontStyle={"normal"}>
                        Risk Level


                    </Typography>
                    <Typography
                        paddingLeft={1}

                        sx={{

                            textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 400 },
                            fontSize: window.$isMobile ? 16 : 16, lineHeight: '105%', color: "#212325"
                        }} fontStyle={"normal"}>
                        {e.riskLevel}


                    </Typography>
                    <Typography
                        paddingTop={2}

                        sx={{

                            textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 600 },
                            fontSize: window.$isMobile ? 16 : 16, lineHeight: '105%', color: "#006ec2"
                        }} fontStyle={"normal"}>
                        Mitigation Suggestions


                    </Typography>
                    {e.mitigationSuggestions && e.mitigationSuggestions.map((v) => (
                        <Typography
                            key={v}
                            paddingLeft={1}
                            sx={{

                                textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 400 },
                                fontSize: window.$isMobile ? 16 : 16, lineHeight: '105%', color: "#212325"
                            }} fontStyle={"normal"}>
                            {v}


                        </Typography>
                    ))}
                    <Typography

                        paddingTop={2}
                        sx={{

                            textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 600 },
                            fontSize: window.$isMobile ? 16 : 16, lineHeight: '105%', color: "#006ec2"
                        }} fontStyle={"normal"}>
                        References


                    </Typography>

                    {e.referenceSnippets && e.referenceSnippets.map((v) => (
                        <Typography
                            key={v}
                            paddingLeft={1}
                            sx={{

                                textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 400 },
                                fontSize: window.$isMobile ? 16 : 16, lineHeight: '105%', color: "#212325"
                            }} fontStyle={"normal"}>
                            {v}


                        </Typography>
                    ))}

                    <Typography

                        paddingTop={2}
                        sx={{

                            textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 600 },
                            fontSize: window.$isMobile ? 16 : 16, lineHeight: '105%', color: "#006ec2"
                        }} fontStyle={"normal"}>
                        Risk Type


                    </Typography>
                    <Typography

                        paddingLeft={1}
                        paddingBottom={3}
                        sx={{

                            textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 400 },
                            fontSize: window.$isMobile ? 16 : 16, lineHeight: '105%', color: "#212325"
                        }} fontStyle={"normal"}>
                        {e.riskType}


                    </Typography>
                    <Divider sx={{ marginBottom: 4, backgroundColor: "#006ec2", width: "100%", borderBottomWidth: 10 }} variant="fullWidth" />
                </Stack>
            ))}


        </Stack>
    )

}

export default RiskChecker