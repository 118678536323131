import React, { useState, useEffect, useRef } from 'react'
import axios from '../../Login/axiosConfig.js'

import {
    TextField, Divider, Backdrop, CircularProgress,
    Button, FormGroup, FormControl, OutlinedInput, InputAdornment, IconButton, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Stack, Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';



const RiskPolicies = ({ fcn, data, user, addRiskSiloData, getAllPolicies, globalRiskSiloId, setGlobalRiskSiloId,clientCompanies, globalClientCompanyId, setGlobalClientCompanyId }) => {
    const [newPolicy, setNewPolicy] = useState("")
    const [newPolicies, setNewPolicies] = useState([])
    const [policyUpdater, setPolicyUpdater] = useState(0)
  
   
    

    const handleAddNewPolicy = (e) => {

        if (e === "") {
            return
        }
        let temp = newPolicies
        temp.push(e)

        setNewPolicies(temp)
        console.log("all policies:", temp)
        setNewPolicy("")

    };




    const handleSubmit = () => {
        if (newPolicies.length === 0) {
            return
        }
    
        const register = {
            riskSiloId: globalRiskSiloId,
            policies: newPolicies,
            clientCompanyId: globalClientCompanyId
        }
        console.log("data from submit", register)
        fcn(register)

    }

    return (
        <Stack direction="column" sx={{
            backgroundColor: "#F5F7F8", borderRadius: 5,
            padding: 2.5,
            paddingTop: 5,

        }} >
           

            <Typography marginTop={0} sx={{ fontSize: 26, typography: { fontFamily: "Roboto", fontWeight: 900 }, lineHeight: '90%', color: "#006ec2" }} fontStyle={"normal"} component="div">
                Policies
            </Typography>

            <Stack marginTop={3} marginBottom={10} spacing={3}>
            {clientCompanies && <FormControl
                    sx={{ marginBottom: 4 }}
                    fullWidth >
                    <InputLabel id="demo-simple-select-label">Client Companies</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={globalClientCompanyId}
                        label="Client Companies"
                        onChange={(event) => {
                          
                            setGlobalClientCompanyId(event.target.value)

                        }}
                    >
                        {clientCompanies.map((e) => (
                            <MenuItem key={e.clientCompanyId} value={e.clientCompanyId}>{e.companyName}</MenuItem>
                        ))}


                    </Select>
                </FormControl>}

                {addRiskSiloData && <FormControl
                    fullWidth >
                    <InputLabel id="demo-simple-select-label">Risk Silo</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={globalRiskSiloId}
                        label="Target Recipient"
                        onChange={(event) => {
                            setGlobalRiskSiloId(event.target.value)

                        }}
                    >
                        {addRiskSiloData.map((e) => (
                            <MenuItem key={e.riskSiloId} value={e.riskSiloId}>{e.riskSiloName}</MenuItem>
                        ))}


                    </Select>
                </FormControl>}

                <FormControl
                    fullWidth
                    sx={{ m: 1 }} variant="outlined">
                    <InputLabel >Add new policy</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"

                        onChange={(e) => {
                            setNewPolicy(e.target.value)
                        }}
                        fullWidth
                        value={newPolicy}
                        type={'text'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                        handleAddNewPolicy(newPolicy)
                                    }}
                                    edge="end"
                                >
                                    <AddIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                       label="Add new policy"
                    />
                </FormControl>

                {newPolicies.length != 0 && newPolicies.map((val, index) => (

                    <FormControl
                        key={val}
                        fullWidth
                        sx={{ m: 1 }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Policy</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"

                            disabled
                            fullWidth
                            value={newPolicies[index]}
                            type={'text'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="remove"
                                        onClick={() => {
                                            let temp = newPolicies
                                            temp.splice(index, 1);
                                            console.log("new temp array")
                                            setNewPolicies(temp)
                                            setPolicyUpdater(policyUpdater + 1)
                                        }}
                                        edge="end"
                                    >
                                        <RemoveIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Policy"
                        />
                    </FormControl>

                ))}



                <Stack width={"100%"} direction="row" spacing={2} marginTop={2} justifyContent={"flex-end"} >

                    <Button onClick={() => {
                        setNewPolicies([])
                        setNewPolicy("")

                    }}>Clear</Button>
                    <Button onClick={handleSubmit} >Submit</Button>
                </Stack>

            </Stack>

            {data && data.map((e) => (
                <Stack key={e.policyId}>
                    <Typography


                        sx={{
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                            textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 900 },
                            fontSize: window.$isMobile ? 42 : 64, lineHeight: '105%', color: "#212325"
                        }} fontStyle={"normal"}>
                        {e.policy}
                    </Typography>
                    <Divider sx={{ marginBottom: 4, backgroundColor: "#006ec2", width: "100%", borderBottomWidth: 10 }} variant="middle" />
                </Stack>
            ))}
        </Stack>
    )

}

export default RiskPolicies