import React, { useEffect, useState } from 'react'
import { Typography, Stack, Box, LinearProgress } from '@mui/material';
import useWindowDimensions from './useWindowDimensions';

const loadingText = "SnackSurvey Loading..."

const loadingTime = 6

const GameLoading = () => {

    const { height, width } = useWindowDimensions();
    const [allText, setAllText] = useState("");
    const [progress, setProgress] = React.useState(0);
    const [progressTime, setProgressTime] = React.useState(40)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        let index = 0
        let textAccumulator = ""

        let textInterval = setInterval(() => {
            textAccumulator = textAccumulator + loadingText[index];
            setAllText(textAccumulator);
            index++
            if (index === loadingText.length) {
                index = 0
                textAccumulator = ""
            }


        }, 150)

        return () => {
            clearInterval(textInterval)
        }
    }, []);

    useEffect(() => {

        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    //  setShowMessages(null)
                    clearInterval(timer);
                }
                // console.log("progressTime",msgObj.timeInSecs)
                const diff = ((100 / loadingTime) * (Math.random() * 2)) / 2
                // console.log("diff", diff)
                return Math.min(oldProgress + (diff), 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);


    return (
        <div style={{
            // backgroundColor: "#f4f5f6",
            backgroundColor: "#212325",
            height: height
            // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23262f71' fill-opacity='0.05'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
        }}>

            <LinearProgress variant="determinate" value={progress} />

            <Stack
                // height={height * .93}
                height={"100%"}
                direction={"column"} alignContent={"center"} justifyContent={"center"}>


                {/* game text */}

                <Stack
                    paddingLeft={"5%"}
                    paddingRight={"5%"}

                    alignItems={"center"}
                  
                    width={"100%"}
                    direction={"column"} >

                    <Typography
                    marginTop={-10}
                        sx={{
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                            textAlign: "center", typography: { fontFamily: "CourierPrime-Bold" },
                            fontSize: window.$isMobile ? 24 : 45, lineHeight: '105%', color: "#86c696"
                        }} fontStyle={"normal"}>
                        {allText}

                    </Typography>


                </Stack>




            </Stack>
        </div>
    )

}

export default GameLoading





