import React, { useState, useEffect, useRef } from 'react'
import axios from '../Login/axiosConfig'
import PropTypes from 'prop-types';
import { Tabs, Tab, Backdrop, CircularProgress, ListItemText, Divider, TextField, Button, Paper, Stack, Typography, Box, Link, Fab } from '@mui/material';
import { convertDomainToValidURL } from '../Helpers/Helpers';
import RiskSilo from './RiskDemo/RiskSilo';
import RiskChecker from './RiskDemo/RiskChecker';
import RiskPolicies from './RiskDemo/RiskPolicies';
import RiskDomainIntel from './RiskDemo/RiskDomainIntel';
import RiskClientCompany from './RiskDemo/RiskClientCompany'


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3, backgroundColor: "#ffffff", width: "100%" }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const allTabs = [
    'SnackRisk Check',
    'Risk Silos',
    'Policies',
    'Domain Intelligence',
    'Client Companies'


]

const RiskCheckerDashboard = ({ user, isAuthenticated, setUser, handleScreenChangeClick, setShowMessages, userFull }) => {

    const [runRiskData, setRunRiskData] = useState(null)
    const [addPolicyData, setAddPolicyData] = useState(null)
    const [addDomainIntelData, setAddDomainIntelData] = useState(null)
    const [addRiskSiloData, setAddRiskSiloData] = useState(null)
    const [globalRiskSiloId, setGlobalRiskSiloId] = useState(null)
    const [value, setValue] = React.useState(0);
    const [showProgress, setShowProgress] = useState(false)
    const [clientCompanies, setClientCompanies] = useState(null)
    const [globalClientCompanyId, setGlobalClientCompanyId] = useState("")

    

    useEffect(() => {
        getClientCompanies()
    }, [])

    useEffect(()=>{
        getRiskSilos()
    }, [globalClientCompanyId])

    useEffect(()=>{
        getAllPolicies()
        getDomainIntel()
    },[globalRiskSiloId])

   

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    // api handlers

    const getRiskSilos = () => {
        if (globalClientCompanyId === null || globalClientCompanyId === "") {
            console.log("bad data", globalClientCompanyId)
            return
        }
        
        axios.get(`/api/v1/getRiskSilos?clientCompanyId=${globalClientCompanyId}`)
            .then(response => {
                console.log("getRiskSilos response", response.data)
                setAddRiskSiloData(response.data)
                // if (!globalRiskSiloId) {
                    setGlobalRiskSiloId(response.data[0].riskSiloId)
                // }


            }).catch(error => {
                // need to figure out way to handle user swiping away from screen and waiting
                console.log("getRiskSilos error:", error)
            })
    }

    const getClientCompanies = () => {
        axios.get('/api/v1/getClientCompanies')
            .then(response => {
                console.log("getClientCompanies response", response.data)
                setClientCompanies(response.data)
                setGlobalClientCompanyId(response.data[0].clientCompanyId)


            }).catch(error => {
                // need to figure out way to handle user swiping away from screen and waiting
                console.log("getClientCompanies error:", error)
            })
    }

    const addClientCompany = (data) => {
        if (!isAuthenticated) {
            setShowMessages({
                alertType: "error",
                title: "Not Logged In",
                message: "Please create an account of log in to add a policy",
            })
            return
        }
        setShowProgress(true)

        console.log("data check:", data)

        axios.post('/api/v1/addClientCompany', data)
            .then(response => {
                getClientCompanies()
                console.log("getClientCompanies:", response.data)
                setShowProgress(false)
            }).catch(error => {
                // need to figure out way to handle user swiping away from screen and waiting
                console.log(error)
                setShowProgress(false)
            })
    }

    const runRisk = (data) => {
        console.log("data check:", data)
        setShowProgress(true)

        axios.post('/api/v1/runRisk', data)
            .then(response => {
                setRunRiskData(response.data)
                console.log("runRisk:", response.data)
                setShowProgress(false)
            }).catch(error => {
                setShowProgress(false)
                // need to figure out way to handle user swiping away from screen and waiting
                console.log(error)
            })
    }



    const addPolicy = (data) => {
        if (!isAuthenticated) {
            setShowMessages({
                alertType: "error",
                title: "Not Logged In",
                message: "Please create an account of log in to add a policy",
            })
            return
        }
        setShowProgress(true)

        console.log("data check:", data)

        axios.post('/api/v1/addPolicy', data)
            .then(response => {
                getAllPolicies(data.riskSiloId)
                console.log("addPolicy:", response.data)
                setShowProgress(false)
            }).catch(error => {
                // need to figure out way to handle user swiping away from screen and waiting
                console.log(error)
                setShowProgress(false)
            })
    }

    const getAllPolicies = () => {
        if (globalClientCompanyId === null || globalClientCompanyId === "") {
            return
        }
        if (globalRiskSiloId === null || globalRiskSiloId === "") {
            return
        }
        console.log("getAllPolicies --> globalRiskSiloId:", globalRiskSiloId, "globalClientCompanyId", globalClientCompanyId)

        axios.get(`/api/v1/getPolicies?riskSiloId=${globalRiskSiloId}&clientCompanyId=${globalClientCompanyId}`)
            .then(response => {
                setAddPolicyData(response.data)
                console.log("getPolicies:", response.data)
            }).catch(error => {
                // need to figure out way to handle user swiping away from screen and waiting
                console.log(error)
            })
    }

    const getDomainIntel = () => {
        console.log("getDomainIntel --> globalRiskSiloId:", globalRiskSiloId, "globalClientCompanyId", globalClientCompanyId)
        if (globalClientCompanyId === null || globalClientCompanyId === "") {
            return
        }
        if (globalRiskSiloId === null || globalRiskSiloId === "") {
            return
        }
        axios.post(`/api/v1/getDomainIntel?riskSiloId=${globalRiskSiloId}&clientCompanyId=${globalClientCompanyId}`)
            .then(response => {
                setAddDomainIntelData(response.data)
                console.log("getDomainIntel:", response.data)
            }).catch(error => {
                // need to figure out way to handle user swiping away from screen and waiting
                console.log(error)
            })
    }

    const addDomainIntel = (data) => {
        console.log("data check:", data)
        if (!isAuthenticated) {
            setShowMessages({
                alertType: "error",
                title: "Not Logged In",
                message: "Please create an account of log in to add domain intelligence",
            })
            return
        }
        setShowProgress(true)

        axios.post('/api/v1/addDomainIntel', data)
            .then(response => {
                getDomainIntel(data.riskSiloId)

                console.log("addDomainIntel:",)
                setShowProgress(false)
            }).catch(error => {
                // need to figure out way to handle user swiping away from screen and waiting
                console.log(error)
                setShowProgress(false)

            })
    }

    const addRiskSilo = (data) => {
        console.log("data addRiskSilo:", data)

        // prep for silos
        let siloArray = data.newRiskSilos.map((e) => {
            return {
                riskSiloName: e,
                clientCompanyId: globalClientCompanyId
            }
        })

        //
      
        if (!isAuthenticated) {
            setShowMessages({
                alertType: "error",
                title: "Not Logged In",
                message: "Please create an account of log in to add a risk silo",
            })
            return
        }


        setShowProgress(true)
        axios.post('/api/v1/addRiskSilo', siloArray)
            .then(response => {
                getRiskSilos()
                console.log("addRiskSilo:", response.data)
                setShowProgress(false)
            }).catch(error => {
                // need to figure out way to handle user swiping away from screen and waiting
                console.log(error)
                setShowProgress(false)
            })
    }



    return (
        <Box sx={{ width: "100%", backgroundColor: "#ffffff", }}>
             <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showProgress}

            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box sx={{ backgroundColor: "#ffffff", maxWidth: { xs: 360, sm: 480, md: "100%", lg: "100%", xl: "100%" } }}>
                <Tabs
                    value={value}
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={handleChange}
                    aria-label="risk checker demo"
                >
                    <Tab label={allTabs[0]} {...a11yProps(0)} sx={{ color: "#212325" }} />
                    <Tab label={allTabs[1]} {...a11yProps(1)} sx={{ color: "#212325" }} />
                    <Tab label={allTabs[2]} {...a11yProps(2)} sx={{ color: "#212325" }} />
                    <Tab label={allTabs[3]} {...a11yProps(3)} sx={{ color: "#212325" }} />
                    <Tab label={allTabs[4]} {...a11yProps(3)} sx={{ color: "#212325" }} />
                    {/* <Tab label={allTabs[4]} {...a11yProps(4)} sx={{ color: "#212325" }} /> */}
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <RiskChecker fcn={runRisk} data={runRiskData}
                    user={user} addRiskSiloData={addRiskSiloData}
                    clientCompanies={clientCompanies}
                    globalClientCompanyId={globalClientCompanyId} setGlobalClientCompanyId={setGlobalClientCompanyId}
                    globalRiskSiloId={globalRiskSiloId} setGlobalRiskSiloId={setGlobalRiskSiloId}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <RiskSilo fcn={addRiskSilo} data={null} user={user} 
                 clientCompanies={clientCompanies}
                 globalClientCompanyId={globalClientCompanyId} setGlobalClientCompanyId={setGlobalClientCompanyId}

                 globalRiskSiloId={globalRiskSiloId} setGlobalRiskSiloId={setGlobalRiskSiloId}
                addRiskSiloData={addRiskSiloData} />

            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <RiskPolicies fcn={addPolicy} data={addPolicyData}
                 clientCompanies={clientCompanies}
                 globalClientCompanyId={globalClientCompanyId} setGlobalClientCompanyId={setGlobalClientCompanyId}

                    globalRiskSiloId={globalRiskSiloId} setGlobalRiskSiloId={setGlobalRiskSiloId}
                    getAllPolicies={getAllPolicies} user={user} addRiskSiloData={addRiskSiloData} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                <RiskDomainIntel fcn={addDomainIntel} data={addDomainIntelData}
                 clientCompanies={clientCompanies}
                 globalClientCompanyId={globalClientCompanyId} setGlobalClientCompanyId={setGlobalClientCompanyId}

                    globalRiskSiloId={globalRiskSiloId} setGlobalRiskSiloId={setGlobalRiskSiloId}
                    getDomainIntel={getDomainIntel} user={user} addRiskSiloData={addRiskSiloData} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
                <RiskClientCompany fcn={addClientCompany} clientCompanies={clientCompanies} />
            </CustomTabPanel>

        </Box>
    )

}

export default RiskCheckerDashboard